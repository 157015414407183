import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "modal top4-modal",
  role: "dialog",
  "aria-live": "assertive"
}
const _hoisted_2 = { class: "modal-headline" }
const _hoisted_3 = { class: "add-facility-modal-headline" }
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = { class: "upper" }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = {
  key: 0,
  class: "left"
}
const _hoisted_8 = ["title", "aria-label"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["title", "aria-label", "innerHTML"]
const _hoisted_11 = ["data-url", "title", "aria-label"]
const _hoisted_12 = ["title", "aria-label"]
const _hoisted_13 = ["title", "aria-label"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")
  const _component_BaseRecaptcha = _resolveComponent("BaseRecaptcha")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[6] || (_cache[6] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString($options.trimmedCityName) + ", " + _toDisplayString($options.stateDisplayName), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h5", _hoisted_5, _toDisplayString($options.trimmedFacilityName), 1),
        ($options.noVideo)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "top4-modal-poster",
              src: $props.facility.thumbUrl,
              alt: $options.trimmedFacilityName
            }, null, 8, _hoisted_6))
          : (_openBlock(), _createBlock(_component_VideoPlayer, {
              key: 1,
              class: "top4-modal-video",
              options: $options.videoOptions($options.localeVideo, $props.facility.thumbUrl),
              "aria-label": $setup.t('top4_modal:alt_text.video'),
              onPlay: $options.trackPlay
            }, null, 8, ["options", "aria-label", "onPlay"])),
        _createElementVNode("div", {
          class: _normalizeClass(["top4-modal-ctas", { voting: _ctx.inPhase('voting') }])
        }, [
          (_ctx.inPhase('voting'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_ctx.isLoggedIn && !$data.isShowingVoteConfirm)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      ($options.isRecaptchaVisible)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(["recaptcha-container", { 'error': $data.showRecaptchaError }])
                          }, [
                            _createVNode(_component_BaseRecaptcha, {
                              ref: "recaptcha",
                              label: $setup.t('field.g-recaptcha-response'),
                              error: $setup.t('field.g-recaptcha-response'),
                              "is-automated-test": _ctx.app.isAutomatedTest
                            }, null, 8, ["label", "error", "is-automated-test"])
                          ], 2))
                        : _createCommentVNode("", true),
                      _createElementVNode("button", {
                        class: "button vote-btn",
                        title: $setup.t('top4_modal:vote'),
                        "aria-label": $setup.t('top4_modal:vote'),
                        onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.onVote && $options.onVote(...args)), ["prevent"]))
                      }, _toDisplayString($setup.t('top4_modal:vote')), 9, _hoisted_8)
                    ], 64))
                  : (_ctx.isLoggedIn && $data.isShowingVoteConfirm)
                    ? (_openBlock(), _createElementBlock("p", {
                        key: 1,
                        class: "vote-confirm-copy",
                        onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.toggleBackToVote && $options.toggleBackToVote(...args)), ["prevent"])),
                        innerHTML: $setup.t('top4_modal:vote_confirmation')
                      }, null, 8, _hoisted_9))
                    : (_openBlock(), _createElementBlock("button", {
                        key: 2,
                        class: "button sign-in",
                        title: $setup.t('top4_modal:sign_in'),
                        "aria-label": $setup.t('top4_modal:sign_in'),
                        onClick: _cache[2] || (_cache[2] = (...args) => ($options.openLoginModal && $options.openLoginModal(...args))),
                        innerHTML: $setup.t('top4_modal:sign_in')
                      }, null, 8, _hoisted_10))
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["top4-modal-shares", `phase-${_ctx.app.phase}`])
              }, [
                _createElementVNode("a", {
                  class: "button facebook_share",
                  href: "#",
                  "data-url": $options.shareUrl,
                  title: $setup.t('top4_modal:share'),
                  "aria-label": $setup.t('top4_modal:share'),
                  onClick: _cache[3] || (_cache[3] = $event => ($options.trackUrchinModal('share', `top4-facebook-${$options.facilityNameNoSpaces}`)))
                }, [
                  _cache[7] || (_cache[7] = _createElementVNode("i", { class: "fab fa-facebook" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString($setup.t('top4_modal:share')), 1)
                ], 8, _hoisted_11)
              ], 2)),
          _createElementVNode("a", {
            href: "#",
            class: "button show-story",
            title: $setup.t('top4_modal:see_story'),
            "aria-label": $setup.t('top4_modal:see_story'),
            onClick: _cache[4] || (_cache[4] = _withModifiers((...args) => ($options.goToEntry && $options.goToEntry(...args)), ["prevent"]))
          }, _toDisplayString($setup.t('top4_modal:see_story')), 9, _hoisted_12)
        ], 2)
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        title: $setup.t('modal_content:titles.close'),
        "aria-label": $setup.t('modal_content:titles.close'),
        onClick: _cache[5] || (_cache[5] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[8] || (_cache[8] = [
        _createElementVNode("i", { class: "fas fa-times" }, null, -1)
      ]), 8, _hoisted_13)
    ])
  ]))
}