import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@public/img/kraft_heinz-logo.png'


const _hoisted_1 = { class: "header" }
const _hoisted_2 = {
  href: "#main-section",
  class: "skip-nav"
}
const _hoisted_3 = { class: "top-bar" }
const _hoisted_4 = ["alt"]
const _hoisted_5 = ["title"]
const _hoisted_6 = {
  key: 0,
  class: "header-nav"
}
const _hoisted_7 = {
  class: "navigation",
  role: "navigation",
  "aria-label": "Navigation"
}
const _hoisted_8 = ["inert"]
const _hoisted_9 = { class: "nav-links-wrapper" }
const _hoisted_10 = ["title"]
const _hoisted_11 = ["href", "title"]
const _hoisted_12 = ["title"]
const _hoisted_13 = {
  key: 0,
  class: "header-cta-wrapper"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LanguageToggle = _resolveComponent("LanguageToggle")
  const _component_SocialIcons = _resolveComponent("SocialIcons")
  const _component_HeaderCTA = _resolveComponent("HeaderCTA")
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, _toDisplayString($setup.t('global:skip_navigation')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        class: "kraft-heinz-logo",
        src: _imports_0,
        alt: $setup.t('links:titles.kraft_heinz')
      }, null, 8, _hoisted_4),
      _createVNode(_component_LanguageToggle, { class: "large-only" }),
      ($options.displayNav())
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "hamburger-menu small-only",
            title: $setup.t('links:titles.mobile_menu'),
            onClick: _cache[0] || (_cache[0] = $event => ($options.showMenu()))
          }, _cache[10] || (_cache[10] = [
            _createElementVNode("span", { class: "mobile-nav-link" }, [
              _createElementVNode("i", { class: "fas fa-bars" })
            ], -1)
          ]), 8, _hoisted_5))
        : _createCommentVNode("", true)
    ]),
    ($options.displayNav())
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("nav", _hoisted_7, [
            _createElementVNode("div", {
              class: _normalizeClass({ 'mobile-menu-overlay': true, active: _ctx.ui.menuOpen }),
              role: "menu"
            }, [
              _createVNode(_component_SocialIcons, {
                class: "large-only",
                "tracking-prefix": "header"
              }),
              _createElementVNode("div", {
                inert: !_ctx.ui.menuOpen && $data.isSmallLayout,
                class: _normalizeClass({ 'nav-links': true, active: _ctx.ui.menuOpen })
              }, [
                _createVNode(_component_LanguageToggle, {
                  class: "small-only",
                  role: "menuitem"
                }),
                _createElementVNode("div", _hoisted_9, [
                  (!_ctx.app.isInMaint)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (!_ctx.inPhase(['pre-launch', 'top4tally', 'winnerreveal', 'expired']))
                          ? (_openBlock(), _createBlock(_component_HeaderCTA, {
                              key: 0,
                              "is-logged-in": _ctx.isLoggedIn,
                              class: "small-only",
                              onClick: _cache[1] || (_cache[1] = $event => {$options.hideMenu();})
                            }, null, 8, ["is-logged-in"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_router_link, {
                          class: "navigation-link",
                          to: $options.goToHome,
                          title: $setup.t('links:home'),
                          role: "menuitem",
                          onClick: _cache[2] || (_cache[2] = $event => {$options.hideMenu(); $options.trackUrchinHeader('navigate', 'navigation-landing')})
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.t('links:home')), 1)
                          ]),
                          _: 1
                        }, 8, ["to", "title"]),
                        _createVNode(_component_router_link, {
                          class: "navigation-link",
                          to: "/how-it-works",
                          title: $setup.t('links:how_it_works'),
                          role: "menuitem",
                          onClick: _cache[3] || (_cache[3] = $event => {$options.hideMenu(); $options.trackUrchinHeader('navigate', 'navigation-how-it-works')})
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.t('links:how_it_works')), 1)
                          ]),
                          _: 1
                        }, 8, ["title"]),
                        (!_ctx.inPhase('pre-launch'))
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 1,
                              id: "find_my_arena",
                              href: "#",
                              class: "navigation-link",
                              title: $setup.t('links:find_facility'),
                              role: "menuitem",
                              onClick: _cache[4] || (_cache[4] = _withModifiers($event => {$options.hideMenu(); $options.openFindApprovedFacilityModal(); $options.trackUrchinHeader('navigate', 'navigation-facility')}, ["prevent"]))
                            }, _toDisplayString($setup.t('links:find_facility')), 9, _hoisted_10))
                          : _createCommentVNode("", true),
                        (!_ctx.inPhase('pre-launch'))
                          ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 2,
                              to: "/rally-tools",
                              class: "navigation-link",
                              title: $setup.t('links:rally_tools'),
                              role: "menuitem",
                              onClick: _cache[5] || (_cache[5] = $event => {$options.hideMenu(); $options.trackUrchinHeader('navigate', 'navigation-rally')})
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString($setup.t('links:rally_tools')), 1)
                              ]),
                              _: 1
                            }, 8, ["title"]))
                          : _createCommentVNode("", true),
                        (!_ctx.inPhase('pre-launch'))
                          ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 3,
                              to: "/gallery",
                              class: "navigation-link",
                              title: $setup.t('links:gallery'),
                              role: "menuitem",
                              onClick: _cache[6] || (_cache[6] = $event => {$options.hideMenu(); $options.trackUrchinHeader('navigate', 'navigation-gallery')})
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString($setup.t('links:gallery')), 1)
                              ]),
                              _: 1
                            }, 8, ["title"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_router_link, {
                          to: "/legacy",
                          class: "navigation-link",
                          title: $setup.t('links:legacy'),
                          role: "menuitem",
                          onClick: _cache[7] || (_cache[7] = $event => {$options.hideMenu(); $options.trackUrchinHeader('navigate', 'navigation-legacy')})
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.t('links:legacy')), 1)
                          ]),
                          _: 1
                        }, 8, ["title"])
                      ], 64))
                    : _createCommentVNode("", true),
                  _createElementVNode("a", {
                    href: _ctx.app.tb2URL + $setup.t('rules_url'),
                    target: "_blank",
                    class: "navigation-link",
                    title: `${$setup.t('links:rules_copy')} ${$setup.t('links:pdf')}`,
                    role: "menuitem",
                    onClick: _cache[8] || (_cache[8] = $event => ($options.trackUrchinHeader('navigate', 'navigation-rules')))
                  }, _toDisplayString($setup.t('links:rules_link_copy')), 9, _hoisted_11),
                  _createVNode(_component_SocialIcons, {
                    class: "small-only",
                    "tracking-prefix": "header"
                  })
                ]),
                _createElementVNode("a", {
                  class: "mobile-nav-close-link small-only",
                  title: $setup.t('links:titles.mobile_menu_close'),
                  onClick: _cache[9] || (_cache[9] = $event => ($options.hideMenu()))
                }, _cache[11] || (_cache[11] = [
                  _createElementVNode("i", { class: "fal fa-times" }, null, -1)
                ]), 8, _hoisted_12)
              ], 10, _hoisted_8),
              (!_ctx.inPhase(['pre-launch', 'top4tally', 'winnerreveal', 'expired']))
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_component_HeaderCTA, {
                      id: "sign_in_btn",
                      class: "large-only",
                      "is-logged-in": _ctx.isLoggedIn,
                      "end-to-end": 'login-open-modal',
                      "end-to-end-logout": 'header-logout'
                    }, null, 8, ["is-logged-in"])
                  ]))
                : _createCommentVNode("", true)
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}