
    import { useI18next } from '@composables/i18next';
    import { mapGetters } from 'vuex';
    import Validator from '@components/Validator';
    import { BaseCheckbox, BaseFileInput, BaseInput } from '@components/form';
    import { youtubeEntry as validatorGuards } from '../../../lib/validation/client-validators';
    import { track } from '../plugins/WebAnalytics';
    import uploader from '../uploader';

    export default {
        name: 'VideoForm',

        components: {
            Validator,
            BaseCheckbox,
            BaseFileInput,
            BaseInput,
        },

        validatorGuards,

        props: {
            params: {
                type: Object,
                required: true,
            },
            essay: {
                type: Boolean,
                default: true,
                required: false,
            },
        },

        setup () {
            const { t, vT } = useI18next(['entry_form', 'buttons']);
            return { t, vT };
        },

        data () {
            return {
                loading: false,
                videoError: '',
                defaultError: false,
                tempVideo: {
                    type: 'video',
                    video: '',
                    name: '',
                    file: undefined,
                    category_id: 3,
                },
                tempYoutube: {
                    type: 'video',
                    video: '',
                    category_id: 3,
                },
                youtubePlaceholderText: '',
            };
        },

        computed: {
            ...mapGetters({
                getFacility: 'app/getFacility',
            }),

            isVideoSelected () {
                return this.tempVideo.video !== '';
            },

            enabledButton () {
                return (this.isVideoSelected || this.tempYoutube.video) && !this.loading;
            },
        },

        mounted () {
            this.clearYoutubePlaceholder();
        },

        methods: {
            selectVideo (data) {
                this.videoError = '';
                if (data.error) {
                    this.videoError = data.error;
                    return;
                }

                this.tempVideo.video = data.photo;
                this.tempVideo.name = data.name;
                this.tempVideo.file = data.file;
            },

            anotherVideo () {
                this.tempVideo.video = '';
                this.tempVideo.name = '';
            },

            async submitVideo () {
                const { id, name, state } = this.getFacility;
                this.defaultError = false;

                if (this.tempVideo.name != '') {
                    this.tempVideo.facilityId = id;
                    this.tempVideo.facilityName = name;
                    this.tempVideo.facilityProvince = state;
                    this.doSubmit(this.tempVideo);
                    return;
                }

                if (this.tempYoutube.video != '') {
                    this.defaultError = false;
                    this.tempYoutube.facilityId = id;
                    this.tempYoutube.facilityName = name;
                    this.tempYoutube.facilityProvince = state;
                    const { validator } = this.$refs;

                    await validator.validate();

                    if (!validator.isValid) {
                        return;
                    }

                    this.doSubmit(this.tempYoutube, true);
                    return;
                }

                this.defaultError = true;
            },

            async doSubmit (tempForm, isYoutube) {

                try {
                    await uploader.submit(tempForm, isYoutube);
                    track('submission', { category: `${tempForm.facilityName}, video` });
                    this.$emit('result', 'success');
                }
                catch (error) {
                    const typeOfError = error?.response?.data?.result?.error;
                    if (typeOfError === 'limited') {
                        this.$emit('result', 'limited');
                    }
                    else {
                        this.loading = false;
                        this.defaultError = true;
                    }
                }
            },

            clearYoutubePlaceholder () {
                const youtubeTextField = document.getElementById('youtube_text_field');

                if (youtubeTextField) {
                    youtubeTextField.placeholder = '';
                }
            },
        },
    };

