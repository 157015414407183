import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal session-modal",
  role: "dialog"
}
const _hoisted_2 = { class: "modal-headline" }
const _hoisted_3 = { class: "content-wrapper" }
const _hoisted_4 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString($setup.t('headline_copy')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString($setup.t('body_copy')), 1),
        _createElementVNode("button", {
          class: "button",
          "data-e2e": "session-expiration-modal-close",
          onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
        }, _toDisplayString($setup.t('continue')), 1)
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        title: $setup.t('modal_content:titles.close'),
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("i", { class: "fas fa-times" }, null, -1)
      ]), 8, _hoisted_4)
    ])
  ]))
}