import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal story-form story-selection",
  role: "dialog",
  "aria-live": "assertive"
}
const _hoisted_2 = { class: "modal-headline" }
const _hoisted_3 = { class: "content-wrapper" }
const _hoisted_4 = { class: "modal-content ice" }
const _hoisted_5 = ["title", "aria-label"]
const _hoisted_6 = { class: "modal-content" }
const _hoisted_7 = ["title", "aria-label"]
const _hoisted_8 = ["title", "aria-label"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString($setup.t('entry_form:story.headline_copy')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("p", null, null, 512), [
            [_directive_t, 'entry_form:story.write_body_copy']
          ]),
          _createElementVNode("button", {
            id: "start_writing_btn",
            class: "start-writing-btn btn_submit",
            title: $setup.t('buttons:start_writing'),
            "aria-label": $setup.t('buttons:start_writing'),
            onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($options.openEssayModal()), ["prevent"]))
          }, _toDisplayString($setup.t('buttons:start_writing')), 9, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _withDirectives(_createElementVNode("p", null, null, 512), [
            [_directive_t, 'entry_form:story.video_body_copy']
          ]),
          _createElementVNode("button", {
            id: "upload_video_btn",
            class: "upload-video-btn btn_submit",
            title: $setup.t('buttons:upload_video'),
            "aria-label": $setup.t('buttons:upload_video'),
            onClick: _cache[1] || (_cache[1] = _withModifiers($event => ($options.openVideoModal()), ["prevent"]))
          }, _toDisplayString($setup.t('buttons:upload_video')), 9, _hoisted_7)
        ])
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        title: $setup.t('modal_content:titles.close'),
        "aria-label": $setup.t('modal_content:titles.close'),
        onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("i", { class: "far fa-times" }, null, -1)
      ]), 8, _hoisted_8)
    ])
  ]))
}