import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal duplicate-modal",
  role: "dialog",
  "aria-live": "assertive"
}
const _hoisted_2 = { class: "modal-headline" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["title", "disabled", "aria-label", "innerHTML"]
const _hoisted_7 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", {
          id: "dup_account_title",
          innerHTML: $setup.t('duplicate_account.headline_copy')
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "copy",
          innerHTML: $setup.t('duplicate_account.body_copy')
        }, null, 8, _hoisted_5),
        _createElementVNode("form", {
          id: "login_form",
          name: "login_form",
          novalidate: "",
          "aria-labelledby": "dup_account_title",
          onSubmit: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.doLogin && $options.doLogin(...args)), ["prevent"]))
        }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.profile.email) = $event)),
            name: "email",
            class: "email",
            type: "hidden"
          }, null, 512), [
            [_vModelText, _ctx.profile.email]
          ]),
          _createElementVNode("button", {
            type: "submit",
            class: "btn btn_login",
            title: $setup.t('buttons:sign_in'),
            disabled: $data.loading,
            "data-e2e": "login-form-submit",
            "aria-label": $setup.t('buttons:sign_in'),
            innerHTML: $setup.t('buttons:sign_in')
          }, null, 8, _hoisted_6)
        ], 32)
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        "data-e2e": "duplicate-modal-close",
        title: $setup.t('modal_content:titles.close'),
        onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("i", { class: "far fa-times" }, null, -1)
      ]), 8, _hoisted_7)
    ])
  ]))
}