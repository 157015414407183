import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal login-modal",
  "data-e2e": "login-modal",
  role: "dialog",
  "aria-live": "assertive"
}
const _hoisted_2 = { class: "modal-headline" }
const _hoisted_3 = { class: "content-wrapper" }
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = ["title", "aria-label"]
const _hoisted_6 = { class: "modal-content-white top" }
const _hoisted_7 = ["title", "aria-label"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LoginForm = _resolveComponent("LoginForm")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString($setup.t('intro:headline_copy')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h4", null, _toDisplayString($setup.t('not_registered')), 1),
          _createElementVNode("p", null, _toDisplayString($setup.t('not_registered_copy')), 1),
          _createElementVNode("button", {
            id: "register_btn",
            class: "register_btn",
            "data-e2e": "register-open-modal",
            title: $setup.t('buttons:register'),
            "aria-label": $setup.t('buttons:register'),
            onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.openRegModal && $options.openRegModal(...args)), ["prevent"]))
          }, _toDisplayString($setup.t('buttons:register')), 9, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("h4", null, _toDisplayString($setup.t('already_register')), 1),
          _createElementVNode("p", null, _toDisplayString($setup.t('already_register_copy')), 1),
          _createVNode(_component_LoginForm, {
            onLoggedIn: _ctx.closeModal,
            onNotFound: $options.openRegModal
          }, null, 8, ["onLoggedIn", "onNotFound"])
        ])
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        title: $setup.t('modal_content:titles.close'),
        "aria-label": $setup.t('modal_content:titles.close'),
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("i", { class: "far fa-times" }, null, -1)
      ]), 8, _hoisted_7)
    ])
  ]))
}