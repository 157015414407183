const moment = require('moment');

const provinceMap = {
    'CA-AB': 18,
    'CA-BC': 19,
    'CA-MB': 18,
    'CA-NB': 19,
    'CA-NL': 19,
    'CA-NS': 19,
    'CA-NT': 19,
    'CA-NU': 19,
    'CA-ON': 18,
    'CA-PE': 18,
    'CA-QC': 18,
    'CA-SK': 18,
    'CA-YT': 19,
};

module.exports.underAgeCheck = function underAgeCheck (province, age) {
    const prov = String(province).split('-')[1];
    const isUnderAge = (
        (provinceMap[prov] && age < provinceMap[prov]) ||
        (age < 14)
    );
    console.log({ isUnderAge, age });
    return isUnderAge;
};

module.exports.getAge = function getAge (ageFields, currentMoment) {
    let missingField = false;
    ['month', 'day', 'year'].forEach((field) => {
        const key = 'age.birth_' + field;
        if (!isFilled(ageFields[key])) {
            missingField = true;
        }
    });

    if (missingField) {
        return false;
    }

    const birthDate = moment([
        ageFields['age.birth_year'],
        ageFields['age.birth_month'] - 1,
        ageFields['age.birth_day'],
    ]);

    if (!birthDate.isValid()) {
        return false;
    }

    // User is eligible if they are the minimum age as of the currentMoment.
    return currentMoment.diff(birthDate, 'years');
};

function isFilled (value) {
    if (value === null || value === undefined) return false;
    if (typeof value === 'object' && Object.keys(value).length === 0) { return false; }
    const str = String(value);
    if (!str || str.match(/^\s*$/)) return false;
    return true;
}
