
    import { useI18next } from '@composables/i18next';
    import HockeyvilleLogo from '@components/svgs/HockeyvilleLogo.vue';
    import BasePage from './BasePage';

    export default {
        name: 'ErrorPage',

        components: {
            HockeyvilleLogo,
        },

        extends: BasePage,

        setup () {
            const { t, vT } = useI18next('error');
            return { t, vT };
        },

        data () {
            return {
                pageName: 'error',
            };
        },
    };
