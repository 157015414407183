
    import { useI18next } from '@composables/i18next';
    import FacilityList from '@components/FacilityList.vue';
    import { BaseInput } from '@components/form';
    import facilities from '../facilities';
    import openModal, { FindFacilityGoogleModal } from '../modals';
    import BaseModal from './BaseModal';

    export default {
        name: 'FindApprovedFacilityModal',

        components: {
            FacilityList,
            BaseInput,
        },

        extends: BaseModal,

        setup () {
            const { t, vT } = useI18next(['facility_search', 'global', 'profile', 'modal_content']);
            return { t, vT };
        },

        data () {
            return {
                modalName: 'find-approved-facility',
                searchingTest: '',
                search: '',
                displayList: [],
                searchList: [],
                fullList: [],
                idList: [],
                filterKeys: [
                    'state',
                    'city',
                    'zip',
                    'name',
                ],
                currentPage: 0,
                numberPerPage: 100,
                delaySearch: 0,
                isLoading: true,
            };
        },

        computed: {
            showNoResults () {
                return this.displayList.length == 0;
            },
        },

        created () {
            this.getFacilities();
        },

        methods: {

            openAddFacilityModal () {
                openModal(FindFacilityGoogleModal);
                this.closeModal();
            },

            async getFacilities () {
                let facilitiesResponse;
                // this.trackEvent('submit', `facility-search:${payload.query}-in-${payload.state}`);
                try {
                    facilitiesResponse = await facilities.all();
                }
                catch (err) {
                    console.error(err);
                    // this.$router.push({ path: `/error` });
                    return;
                }

                this.fullList = facilitiesResponse.data.facilities;

                this.searchFacilities();
            },

            searchFacilities () {
                clearTimeout(this.delaySearch);
                this.displayList = [];
                this.searchList = [];
                this.idList = [];
                this.isLoading = true;

                this.delaySearch = setTimeout(() =>{
                    this.currentPage = 0;

                    if (this.search != '') {
                        for (const key of this.filterKeys) {
                            this.searchList = this.searchList.concat(this.filterBy(key));
                        }
                    }
                    else {
                        this.searchList = [...this.fullList];
                    }

                    this.displayList = this.searchList.slice(this.currentPage * this.numberPerPage, ((this.currentPage + 1) * this.numberPerPage));
                    this.isLoading = false;
                }, 500);
            },

            filterBy (key) {
                return this.fullList.filter((element) =>{
                    let inSearch = element.data[key] && String(element.data[key]).toLowerCase().includes(this.search.toLowerCase());
                    inSearch = inSearch && !this.idList.includes(element.data.id);
                    if (inSearch) {
                        this.idList.push(element.data.id);
                    }
                    return inSearch;
                });
            },

            onScroll (event) {
                const facilityListComponent = event.target;
                const allFacilities = facilityListComponent.querySelectorAll('.facility-item');
                const triggerFacility = allFacilities.at(-10);

                if (!triggerFacility) {
                    return;
                }

                const triggerFacilityPosition = triggerFacility.getBoundingClientRect(),
                      facilityListBottom = facilityListComponent.offsetTop + facilityListComponent.clientHeight;

                if (triggerFacilityPosition.top <= facilityListBottom) {
                    this.addMore();
                }
            },

            addMore () {
                if (this.displayList.length >= this.fullList.length) {
                    return;
                }

                this.currentPage++;
                this.displayList = this.displayList.concat(this.searchList.slice(this.currentPage * this.numberPerPage, (this.currentPage + 1) * this.numberPerPage));
            },

        },
    };
