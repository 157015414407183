
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'DuplicateFacilityModal',

        extends: BaseModal,

        props: {
            facilityId: {
                type: String,
                default: '',
            },
        },

        setup () {
            const { t, vT } = useI18next(['dup_facility_modal', 'buttons', 'modal_content']);
            return { t, vT };
        },

        methods: {
            goToFacility () {
                this.$router.push({ path: `community/${this.facilityId}` });
                this.closeModal();
            },
        },

    };
