
    import { useI18next } from '@composables/i18next';
    import { mapState } from 'vuex';

    export default {
        name: 'SocialIcons',

        props: {
            trackingPrefix: {
                type: String,
                default: '',
            },
        },

        setup () {
            const { t, vT } = useI18next(['links']);
            return { t, vT };
        },

        computed: {
            ...mapState([
                'app',
                'profile',
            ]),
        },

        methods: {
            trackUrchinSocial (action, category) {
                this.$webAnalytics.track(action, { category });
            },
        },
    };
