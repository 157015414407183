import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal ineligible-modal",
  role: "dialog",
  "aria-live": "assertive"
}
const _hoisted_2 = { class: "modal-headline" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["title", "aria-label"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", {
          innerHTML: $setup.t('ineligible_age.headline_copy')
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", {
        class: "modal-content",
        innerHTML: $setup.t('ineligible_age.body_copy', { rulesLink: $options.rulesLink, interpolation: { escapeValue: false } })
      }, null, 8, _hoisted_4),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        "data-e2e": "ineligible-modal-close",
        title: $setup.t('modal_content:titles.close'),
        "aria-label": $setup.t('modal_content:titles.close'),
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("i", { class: "far fa-times" }, null, -1)
      ]), 8, _hoisted_5)
    ])
  ]))
}