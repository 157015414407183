import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "g-recaptcha-inline-error" }
const _hoisted_2 = { class: "g-recaptcha-box" }
const _hoisted_3 = ["data-sitekey"]
const _hoisted_4 = {
  key: 0,
  class: "error-message aca-error-message",
  role: "alert",
  "aria-live": "assertive"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["g-recaptcha-response-container", { error: $props.isError }])
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString($props.label), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        ref: "gRecaptcha",
        class: "g-recaptcha",
        "data-callback": "recaptchaComplete",
        "data-expired-callback": "recaptchaExpired",
        "data-sitekey": $options.recaptchaV2PublicKey
      }, null, 8, _hoisted_3)
    ]),
    ($props.isError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($props.error), 1))
      : _createCommentVNode("", true)
  ], 2))
}