
    import { useI18next } from '@composables/i18next';
    import axios from 'axios';
    import moment from 'moment';
    import { mapActions, mapMutations, mapState } from 'vuex';
    import { getAge, underAgeCheck } from '@components/Age';
    import { RenderlessValidator } from 'rocketship-validator';
    import { BaseCheckbox, BaseInput, BaseSelect, BaseRecaptcha } from '@components/form';
    import NHLLogos from '@components/NHLLogos.vue';
    import { register as validatorGuards } from '../../../lib/validation/client-validators';
    import api from '../api';
    import store from '../store/index';
    import openModal, { ErrorModal, DuplicateAccountModal, IneligibleModal } from '../modals';
    import { setRecaptchaV2Challenge, setRecaptchaV3Challenge } from '../recaptcha';
    import { track } from '../plugins/WebAnalytics';
    import dobData from '../utils/dateOfBirth';
    export default {

        components: {
            RenderlessValidator,
            BaseCheckbox,
            BaseInput,
            BaseSelect,
            NHLLogos,
        },

        emits: ['registered', 'duplicate-account', 'ineligible-age'],

        setup () {
            const { t, vT } = useI18next(['registration', 'profile', 'global', 'intro']);
            return { t, vT };
        },

        data () {
            return {
                loading: false,
                ageOptions: dobData,
                ageDefined: false,
                isChild: true,
                states: [],
                storeOptions: [
                    {
                        label: 'Store 1',
                        value: 'store_1',
                    },
                    {
                        label: 'Store 2',
                        value: 'store_2',
                    },
                    {
                        label: 'Store 3',
                        value: 'store_3',
                    },
                ],
                // Collect fields before we submit the form.
                tempProfile: {
                    // Set initial/default values for these fields.
                    'age.birth_day': '',
                    'age.birth_month': '',
                    'age.birth_year': '',
                    primary_opt_in: false, // pre-check the opt-in

                    // If there's already profile data in the Vuex store, such as from the
                    // email field on the login page, or other data collected before we got
                    // to this page, merge that in as well, so the fields are prefilled.
                    ...store.state.profile,
                },
                errorNode: 'default',
                validatorGuards,
            };
        },

        computed: {
            ...mapState(['app']),

            age () {
                const ageData = {
                    'age.birth_day': this.tempProfile['age.birth_day'],
                    'age.birth_month': this.tempProfile['age.birth_month'],
                    'age.birth_year': this.tempProfile['age.birth_year'],
                };
                return getAge(ageData, moment(this.app.now));
            },

            stateOptions () {
                return this.states && this.states.map(
                    (state) => ({
                        label: this.t(`global:state.${state}.name`) || state,
                        value: state,
                    }),
                ) || [{ label: '', value: '' }];
            },
        },

        created () {
            this.loadConfig();
        },

        methods: {
            ...mapActions({
                register: 'profile/register',
            }),

            ...mapMutations({
                updateProfile: 'profile/updateProfile',
            }),

            checkAge () {
                this.ageDefined = !underAgeCheck(undefined, this.age);
                return this.ageDefined;
            },

            trackUrchinNhlCreateAccount (action, category) {
                this.$webAnalytics.track(action, { category });
            },

            resetAge () {
                if (this.ageDefined === true) {
                    this.checkAge();
                }
            },

            async doRegister (e) {
                const { tempProfile } = this;
                const { validator } = this.$refs;

                tempProfile.ageYearsOld = this.age;

                if (tempProfile.state && !tempProfile.country) {
                    const state = tempProfile.state.split('-');

                    if (state.length > 1) {
                        tempProfile.country = state[0];
                    }
                }
                if (!tempProfile.country) tempProfile.country = 'CA';

                tempProfile.x_channel = store.state.app.channel || undefined;
                tempProfile.affiliate_id = store.state.app.affiliateId || undefined;
                tempProfile.locale = store.state.app.locale || undefined;
                // Allows us to control client-side recaptcha validation.
                tempProfile.isAutomatedTest = store.state.app.isAutomatedTest;

                if (tempProfile.age && tempProfile.age.birth_day) {
                    tempProfile['age.birth_day'] = tempProfile.age.birth_day;
                    tempProfile['age.birth_month'] = tempProfile.age.birth_month;
                    tempProfile['age.birth_year'] = tempProfile.age.birth_year;
                }

                if (tempProfile.mobile_phone_number) {
                    tempProfile.mobile_phone_number = tempProfile.mobile_phone_number.replaceAll(/\D/g, '');
                }

                await setRecaptchaV3Challenge({ data: tempProfile, action: 'register' });

                await validator.validate();
                if (!validator.isValid) {
                    const errorObj = validator.allErrors[0];
                    this.errorNode = 'registration:error_alert';
                    if (errorObj.error === 'NULL') {
                        this.errorNode = 'registration:error_alert';
                    }
                    else if (errorObj.error === 'VALIDATION') {
                        if (errorObj.message === 'failed isChecked test') {
                            this.errorNode = 'registration:error_alert';
                        }
                        else if (errorObj.message === 'failed no4ByteChars test' || errorObj.message === 'failed noEmail test') {
                            this.errorNode = 'registration:error_message_for_name';
                        }
                        else {
                            this.errorNode = 'registration:error_correct_highlighted';
                        }
                    }
                    return;
                }

                track('Valid Submission', { category: 'Register Form' });

                this.loading = true;

                try {
                    await this.register({ tempProfile });

                    this.loading = false;
                    this.$emit('close');
                }
                catch (error) {
                    this.loading = false;

                    const { response } = error;

                    if (response && response.status === 409) {
                        this.updateProfile(tempProfile);
                        this.$emit('close', 'duplicate-account');
                        openModal(DuplicateAccountModal, { tempProfile });
                    }
                    else if (response && response.data && response.data.errors) {

                        validator.setAllErrors(response.data.errors);

                        if (validator.isAnyErrorMessageLike(/minAge/i)) {
                            this.$emit('close', 'ineligible-age');
                            openModal(IneligibleModal);
                            return;
                        }
                    }
                    else {
                        openModal(ErrorModal);
                    }
                }
            },

            async loadConfig () {
                try {
                    const response = await axios.get(`${api.base}/states/config`);
                    const { states, countries } = response.data;
                    if (states.length > 0) {
                        // if no states are present do not change default data
                        this.states = states;
                        this.countries = countries;
                    }
                }
                catch (ex) {
                    console.error(ex);
                    openModal(ErrorModal);
                }
            },
        },
    };
