import { vShow as _vShow, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "error error-message",
  "data-e2e": "register-form-error"
}
const _hoisted_2 = { name: "birth-date" }
const _hoisted_3 = {
  id: "age",
  ref: "age",
  class: "age"
}
const _hoisted_4 = ["aria-label"]
const _hoisted_5 = { name: "user_info" }
const _hoisted_6 = ["inert"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "reg-form" }
const _hoisted_9 = { class: "reg-row" }
const _hoisted_10 = { class: "reg-row" }
const _hoisted_11 = { class: "reg-row" }
const _hoisted_12 = { class: "nhl-opt-in" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["inert"]
const _hoisted_15 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseSelect = _resolveComponent("BaseSelect")
  const _component_BaseInput = _resolveComponent("BaseInput")
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")
  const _component_NHLLogos = _resolveComponent("NHLLogos")
  const _component_RenderlessValidator = _resolveComponent("RenderlessValidator")
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createBlock(_component_RenderlessValidator, {
    ref: "validator",
    guards: $data.validatorGuards,
    data: $data.tempProfile,
    class: "register-form"
  }, {
    default: _withCtx(({
            hasErrors, isError,
        }) => [
      _withDirectives(_createElementVNode("p", _hoisted_1, null, 512), [
        [_vShow, hasErrors],
        [_directive_t, $data.errorNode]
      ]),
      _createElementVNode("form", {
        id: "age_gate",
        class: "register_form",
        name: "age_gate",
        autocomplete: "",
        novalidate: "",
        "data-e2e": "age-gate",
        onSubmit: _cache[3] || (_cache[3] = _withModifiers((...args) => ($options.checkAge && $options.checkAge(...args)), ["prevent"]))
      }, [
        _createElementVNode("fieldset", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BaseSelect, {
              modelValue: $data.tempProfile['age.birth_day'],
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.tempProfile['age.birth_day']) = $event)),
              name: "age.birth_day",
              label: $setup.t('registration:age_birth_day'),
              error: $setup.t('registration:age_birth_day'),
              "select-one": $setup.t('global:day_header'),
              "is-error": isError('age'),
              options: $data.ageOptions.days,
              class: "age-input",
              onInput: $options.resetAge
            }, null, 8, ["modelValue", "label", "error", "select-one", "is-error", "options", "onInput"]),
            _createVNode(_component_BaseSelect, {
              modelValue: $data.tempProfile['age.birth_month'],
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.tempProfile['age.birth_month']) = $event)),
              name: "age.birth_month",
              label: $setup.t('registration:age_birth_month'),
              error: $setup.t('registration:age_birth_month'),
              "select-one": $setup.t('global:month_header'),
              "is-error": isError('age'),
              options: $data.ageOptions.months,
              class: "age-input",
              onInput: $options.resetAge
            }, null, 8, ["modelValue", "label", "error", "select-one", "is-error", "options", "onInput"]),
            _createVNode(_component_BaseSelect, {
              modelValue: $data.tempProfile['age.birth_year'],
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.tempProfile['age.birth_year']) = $event)),
              name: "age.birth_year",
              label: $setup.t('registration:age_birth_year'),
              error: $setup.t('registration:age_birth_year'),
              "select-one": $setup.t('global:year_header'),
              "is-error": isError('age'),
              options: $data.ageOptions.years,
              class: "age-input",
              onInput: $options.resetAge
            }, null, 8, ["modelValue", "label", "error", "select-one", "is-error", "options", "onInput"])
          ], 512),
          _createElementVNode("button", {
            title: "Submit",
            type: "submit",
            "aria-label": $setup.t('buttons:submit')
          }, null, 8, _hoisted_4)
        ])
      ], 32),
      _createElementVNode("form", {
        id: "register_form",
        class: "register_form",
        name: "register_form",
        autocomplete: "",
        novalidate: "",
        "data-e2e": "register-form",
        onSubmit: _cache[14] || (_cache[14] = _withModifiers((...args) => ($options.doRegister && $options.doRegister(...args)), ["prevent"]))
      }, [
        _createElementVNode("fieldset", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass({ 'reg-modal-disabled': !$data.ageDefined }),
            inert: !$data.ageDefined
          }, [
            _createElementVNode("p", {
              class: "ref-modal-email-disclaimer",
              innerHTML: $setup.t('reg_body_copy_2')
            }, null, 8, _hoisted_7),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_BaseInput, {
                  modelValue: $data.tempProfile.first_name,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.tempProfile.first_name) = $event)),
                  label: $setup.t('field.first_name'),
                  name: "first_name",
                  "is-error": isError('first_name'),
                  error: $setup.t('ada.errors.first_name')
                }, null, 8, ["modelValue", "label", "is-error", "error"]),
                _createVNode(_component_BaseInput, {
                  modelValue: $data.tempProfile.last_name,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.tempProfile.last_name) = $event)),
                  label: $setup.t('field.last_name'),
                  name: "last_name",
                  "is-error": isError('last_name'),
                  error: $setup.t('ada.errors.last_name')
                }, null, 8, ["modelValue", "label", "is-error", "error"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_BaseInput, {
                  modelValue: $data.tempProfile.email,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($data.tempProfile.email) = $event)),
                  label: $setup.t('field.email'),
                  name: "email",
                  "is-error": isError('email'),
                  error: $setup.t('ada.errors.email')
                }, null, 8, ["modelValue", "label", "is-error", "error"]),
                _createVNode(_component_BaseInput, {
                  modelValue: $data.tempProfile.mobile_phone_number,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($data.tempProfile.mobile_phone_number) = $event)),
                  name: "mobile_phone_number",
                  label: $setup.t('field.mobile_phone_number'),
                  "is-error": isError('mobile_phone_number'),
                  class: "phone-number",
                  error: $setup.t('ada.errors.mobile_phone_number')
                }, null, 8, ["modelValue", "label", "is-error", "error"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_BaseSelect, {
                  modelValue: $data.tempProfile.state,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($data.tempProfile.state) = $event)),
                  name: "state",
                  label: $setup.t('field.state'),
                  error: $setup.t('ada.errors.state'),
                  "select-one": '',
                  "is-error": isError('state'),
                  options: $options.stateOptions,
                  class: "province"
                }, null, 8, ["modelValue", "label", "error", "is-error", "options"]),
                _createVNode(_component_BaseInput, {
                  modelValue: $data.tempProfile.zip,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($data.tempProfile.zip) = $event)),
                  name: "zip",
                  label: $setup.t('field.zip'),
                  "is-error": isError('zip'),
                  error: $setup.t('ada.errors.zip'),
                  class: "postal-code"
                }, null, 8, ["modelValue", "label", "is-error", "error"])
              ]),
              ($options.age >= 19)
                ? (_openBlock(), _createBlock(_component_BaseCheckbox, {
                    key: 0,
                    modelValue: $data.tempProfile.rules,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => (($data.tempProfile.rules) = $event)),
                    name: "rules",
                    label: $setup.t('field.rules', { tb2URL: _ctx.app.tb2URL }) || 'I agree to the official rules.',
                    "is-error": isError('rules'),
                    error: $setup.t('ada.errors.rules')
                  }, null, 8, ["modelValue", "label", "is-error", "error"]))
                : (_openBlock(), _createBlock(_component_BaseCheckbox, {
                    key: 1,
                    modelValue: $data.tempProfile.rules,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => (($data.tempProfile.rules) = $event)),
                    name: "rules",
                    label: $setup.t('field.rules_under_19', { tb2URL: _ctx.app.tb2URL }) || 'I agree to the official rules.',
                    "is-error": isError('rules'),
                    error: $setup.t('ada.errors.rules')
                  }, null, 8, ["modelValue", "label", "is-error", "error"])),
              ($options.age > 18)
                ? (_openBlock(), _createBlock(_component_BaseCheckbox, {
                    key: 2,
                    modelValue: $data.tempProfile.marketing_opt_in,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => (($data.tempProfile.marketing_opt_in) = $event)),
                    name: 'marketing_opt_in',
                    label: $setup.t('field.marketing_opt_in', { tb2URL: _ctx.app.tb2URL }),
                    "is-error": isError('marketing_opt_in'),
                    error: $setup.t('field.marketing_opt_in', { tb2URL: _ctx.app.tb2URL })
                  }, null, 8, ["modelValue", "label", "is-error", "error"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_NHLLogos, { class: "nhl-badge-logo" }),
                _createElementVNode("div", {
                  id: "nhl-create-account",
                  onClick: _cache[13] || (_cache[13] = $event => ($options.trackUrchinNhlCreateAccount('exit', 'nhl-create-account-link'))),
                  innerHTML: $setup.t('nhl_opt_in', { tb2URL: _ctx.app.tb2URL })
                }, null, 8, _hoisted_13)
              ])
            ])
          ], 10, _hoisted_6)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["reg-form", { 'reg-modal-disabled': !$data.ageDefined }]),
          inert: !$data.ageDefined
        }, [
          _withDirectives(_createElementVNode("button", {
            class: "button reg-button",
            type: "submit",
            disabled: $data.loading,
            "data-e2e": "register-form-submit"
          }, null, 8, _hoisted_15), [
            [_directive_t, 'buttons:reg']
          ])
        ], 10, _hoisted_14)
      ], 32)
    ]),
    _: 1
  }, 8, ["guards", "data"]))
}