
    import { useI18next } from '@composables/i18next';
    import { mapGetters } from 'vuex';
    import Validator from '@components/Validator';
    import { BaseCheckbox, BaseTextarea, BaseTextareaCounter } from '@components/form';
    import { storyEntry as validatorGuards } from '../../../lib/validation/client-validators';
    import { openPopup } from '../popup';
    import community from '../community';
    import { track } from '../plugins/WebAnalytics';
    import BaseModal from './BaseModal';
    import openModal, { EntryConfirmationModal, EntryLimitedModal } from '.';


    export default {
        name: 'NominationEssayModal',

        components: {
            Validator,
            BaseCheckbox,
            BaseTextarea,
            BaseTextareaCounter,
        },

        extends: BaseModal,
        setup () {
            const { t, vT } = useI18next(['entry_form', 'buttons', 'modal_content']);
            return { t, vT };
        },
        data () {
            return {
                modalName: 'story-form',
                loading: false,
                min: 150,
                max: 1000,
                tempForm: {
                    type: 'story',
                    question1: '',
                    question2: '',
                    question3: '',
                },
                errorRules: false,
                errorLength: false,

            };
        },

        computed: {
            ...mapGetters({
                getFacility: 'app/getFacility',
            }),
            charsLeft () {
                return (n) =>
                    (this.tempForm[`question${n}`]?.trim() || '').length;
            },
        },

        validatorGuards,

        methods: {
            updateCharCount (e, n) {
                this.tempForm[`question${n}`] = e;
            },

            openFAQ (event) {
                if (event.target.tagName == 'A') {
                    event.preventDefault();
                    openPopup(event);
                }
            },

            async doSubmit () {
                const { tempForm } = this;
                const { validator } = this.$refs;

                tempForm.facilityId = this.getFacility.id;
                tempForm.facilityName = this.getFacility.name;
                tempForm.facilityProvince = this.getFacility.state;

                this.errorLength = false;

                await validator.validate();

                if (!validator.isValid) {
                    const rulesError = Object.keys(validator.errorsByName)
                        .filter(element => element.includes('rules'));

                    if (rulesError.length) {
                        this.errorRules = true;
                    }
                    else {
                        this.errorRules = false;
                    }

                    const questionErrors = Object.keys(validator.errorsByType)
                        .filter(element => element.includes('LENGTH'));

                    if (questionErrors.length) {
                        this.errorLength = true;
                    }
                    else {
                        this.errorLength = false;
                    }
                    return;
                }

                this.loading = true;

                try {
                    await community.submitEntry(tempForm);

                    this.loading = false;
                    track('submission', { category: `${tempForm.facilityName}, essay` });
                    this.closeModal();
                    openModal(EntryConfirmationModal);
                }
                catch (error) {
                    this.loading = false;
                    this.closeModal();
                    const typeOfError = error.response.data.result.error;
                    if (typeOfError === 'limited') {
                        openModal(EntryLimitedModal, { type: 'story' });
                    }
                    else {
                        this.$router.push('/error');
                    }
                }
            },
        },
    };
