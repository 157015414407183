import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "modal note-form",
  role: "dialog",
  "aria-live": "assertive"
}
const _hoisted_2 = { class: "modal-headline" }
const _hoisted_3 = { id: "note_form_title" }
const _hoisted_4 = { class: "content-wrapper" }
const _hoisted_5 = { class: "modal-content ice" }
const _hoisted_6 = { class: "notes-details" }
const _hoisted_7 = { class: "font-weight--semibold" }
const _hoisted_8 = { class: "disclaimer large-only" }
const _hoisted_9 = { class: "modal-content top" }
const _hoisted_10 = { class: "date-name bold" }
const _hoisted_11 = {
  key: 0,
  class: "error error-message",
  role: "alert",
  "aria-live": "assertive"
}
const _hoisted_12 = { id: "submit" }
const _hoisted_13 = ["disabled", "title", "aria-label"]
const _hoisted_14 = { class: "modal-content ice small-only note-disclaimer-modal-content" }
const _hoisted_15 = { class: "notes-details" }
const _hoisted_16 = { class: "disclaimer font-weight--semibold" }
const _hoisted_17 = ["title", "aria-label"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseTextarea = _resolveComponent("BaseTextarea")
  const _component_BaseTextareaCounter = _resolveComponent("BaseTextareaCounter")
  const _component_RenderlessValidator = _resolveComponent("RenderlessValidator")
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString($setup.t('notes.headline_copy')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("div", _hoisted_7, null, 512), [
              [_directive_t, 'entry_form:notes.body_copy']
            ]),
            _createElementVNode("p", _hoisted_8, _toDisplayString($setup.t('notes.body_copy_2')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_RenderlessValidator, {
            ref: "validator",
            guards: _ctx.$options.validatorGuards,
            data: $data.tempForm
          }, {
            default: _withCtx(({
                            hasErrors, isError,
                        }) => [
              _createElementVNode("form", {
                id: "note_form",
                name: "note_form",
                autocomplete: "",
                novalidate: "",
                "data-e2e": "note-form",
                "aria-labelledby": "note_form_title",
                onSubmit: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.doSubmit && $options.doSubmit(...args)), ["prevent"]))
              }, [
                _createElementVNode("p", _hoisted_10, _toDisplayString(`${$options.date} / ${$options.name}`), 1),
                hasErrors
                  ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString($setup.t('notes.errors.default')), 1))
                  : _createCommentVNode("", true),
                _createVNode(_component_BaseTextarea, {
                  modelValue: $data.tempForm.note,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.tempForm.note) = $event)),
                  label: '',
                  error: $setup.t('notes.errors.note'),
                  name: "question_1",
                  placeholder: $setup.t('notes.textarea_placeholder_copy'),
                  maxlength: $data.max,
                  "is-error": isError('note'),
                  onInput: $options.updateCharCount
                }, null, 8, ["modelValue", "error", "placeholder", "maxlength", "is-error", "onInput"]),
                _createVNode(_component_BaseTextareaCounter, {
                  class: "character-count",
                  value: $data.tempForm.note,
                  copy: `${$options.charsLeft} ${$setup.t('entry_form:notes.character_count_instructions', { max: $data.max })}`,
                  maxlength: $data.max
                }, null, 8, ["value", "copy", "maxlength"]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("button", {
                    class: "btn btn_submit add_note_submit_btn",
                    type: "submit",
                    disabled: $data.loading,
                    "data-e2e": "story-form-submit",
                    title: $setup.t('buttons:submit'),
                    "aria-label": $setup.t('buttons:submit')
                  }, _toDisplayString($setup.t('buttons:submit')), 9, _hoisted_13)
                ])
              ], 32)
            ]),
            _: 1
          }, 8, ["guards", "data"])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("p", _hoisted_16, _toDisplayString($setup.t('notes.body_copy_2')), 1)
          ])
        ])
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        title: $setup.t('modal_content:titles.close'),
        "aria-label": $setup.t('modal_content:titles.close'),
        onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("i", { class: "far fa-times" }, null, -1)
      ]), 8, _hoisted_17)
    ])
  ]))
}