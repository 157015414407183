import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["inert"]
const _hoisted_2 = ["inert"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TheHeader = _resolveComponent("TheHeader")
  const _component_HeaderNavigation = _resolveComponent("HeaderNavigation")
  const _component_router_view = _resolveComponent("router-view")
  const _component_TheFooter = _resolveComponent("TheFooter")

  return (_openBlock(), _createElementBlock("div", {
    id: "app",
    class: "wrapper",
    inert: _ctx.isModalOpen
  }, [
    ($options.displayHeader)
      ? (_openBlock(), _createBlock(_component_TheHeader, {
          key: 0,
          inert: _ctx.isMenuOpen
        }, null, 8, ["inert"]))
      : _createCommentVNode("", true),
    (_ctx.ui.pageName !== 'faq')
      ? (_openBlock(), _createBlock(_component_HeaderNavigation, { key: 1 }))
      : _createCommentVNode("", true),
    _createElementVNode("main", {
      id: "main-section",
      role: "main",
      inert: _ctx.isMenuOpen
    }, [
      _createVNode(_component_router_view, { class: "content" })
    ], 8, _hoisted_2),
    ($options.displayFooter)
      ? (_openBlock(), _createBlock(_component_TheFooter, {
          key: 2,
          inert: _ctx.isMenuOpen
        }, null, 8, ["inert"]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}