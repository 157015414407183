import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "name"]
const _hoisted_3 = { value: "" }
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 0,
  class: "error-message aca-error-message",
  role: "alert",
  "aria-live": "assertive"
}


export default {
  __name: 'BaseSelect',
  props: /*@__PURE__*/_mergeModels({
        isError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        error: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        selectOne: {
            type: String,
            default: 'Select One',
        },
    }, {
    "modelValue": { type: String, required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

    

    const model = _useModel(__props, "modelValue");

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-select field", { error: __props.isError }])
  }, [
    _createElementVNode("label", { for: __props.name }, _toDisplayString(__props.label), 9, _hoisted_1),
    _withDirectives(_createElementVNode("select", {
      id: __props.name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((model).value = $event)),
      name: __props.name,
      onChange: _cache[1] || (_cache[1] = $event => (_ctx.$emit('input', $event.target.value)))
    }, [
      _createElementVNode("option", _hoisted_3, _toDisplayString(__props.selectOne), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option, index) => {
        return (_openBlock(), _createElementBlock("option", {
          key: `option_${index}`,
          value: option.value
        }, _toDisplayString(option.label), 9, _hoisted_4))
      }), 128))
    ], 40, _hoisted_2), [
      [_vModelSelect, model.value]
    ]),
    (__props.isError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(__props.error), 1))
      : _createCommentVNode("", true)
  ], 2))
}
}

}