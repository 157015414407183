import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "modal facility-search-approved",
  role: "dialog",
  "aria-live": "assertive"
}
const _hoisted_2 = { class: "modal-headline" }
const _hoisted_3 = {
  id: "find_facility_title",
  tabindex: "0"
}
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = {
  tabindex: "0",
  class: "facility-copy"
}
const _hoisted_6 = { class: "facilty-list-wrapper" }
const _hoisted_7 = {
  key: 0,
  class: "loading-state",
  "aria-busy": "true",
  "aria-live": "assertive"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 1,
  class: "no-result"
}
const _hoisted_10 = { class: "trouble" }
const _hoisted_11 = ["title"]
const _hoisted_12 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseInput = _resolveComponent("BaseInput")
  const _component_FacilityList = _resolveComponent("FacilityList")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString($setup.t('facility_finder')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h3", _hoisted_5, _toDisplayString($setup.t('approved_search.body_copy')), 1),
        _createElementVNode("form", {
          role: "search",
          "aria-labelledby": "find_facility_title",
          onSubmit: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.searchFacilities && $options.searchFacilities(...args)), ["prevent"]))
        }, [
          _createVNode(_component_BaseInput, {
            modelValue: $data.search,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.search) = $event)),
            label: $setup.t('approved_search.input_label'),
            error: $setup.t('approved_search.input_label'),
            name: "search",
            "is-error": false,
            placeholder: $setup.t('approved_search.input_placeholder'),
            class: "search",
            onInput: $options.searchFacilities
          }, null, 8, ["modelValue", "label", "error", "placeholder", "onInput"])
        ], 32),
        _createElementVNode("div", _hoisted_6, [
          ($data.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("p", {
                  innerHTML: $setup.t('approved_search.loading_state')
                }, null, 8, _hoisted_8)
              ]))
            : ($options.showNoResults)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("p", null, [
                    _createTextVNode(_toDisplayString($setup.t('approved_search.no_results')) + " ", 1),
                    _createElementVNode("span", _hoisted_10, [
                      _createElementVNode("strong", null, _toDisplayString($setup.t('approved_search.trouble')), 1),
                      _cache[5] || (_cache[5] = _createTextVNode("  ")),
                      _createElementVNode("a", {
                        class: "bold",
                        href: "#",
                        title: $setup.t('approved_search.trouble_link'),
                        onClick: _cache[2] || (_cache[2] = _withModifiers((...args) => ($options.openAddFacilityModal && $options.openAddFacilityModal(...args)), ["prevent"]))
                      }, _toDisplayString($setup.t('approved_search.trouble_link')), 9, _hoisted_11)
                    ])
                  ])
                ]))
              : (_openBlock(), _createBlock(_component_FacilityList, {
                  key: 2,
                  ref: "facilityListComponent",
                  class: "facility-list",
                  facilities: $data.displayList,
                  "has-map": false,
                  role: "list",
                  onClose: _ctx.closeModal,
                  onScroll: $options.onScroll
                }, null, 8, ["facilities", "onClose", "onScroll"]))
        ])
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        role: "button",
        tabindex: "1",
        title: $setup.t('modal_content:titles.close'),
        onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("i", { class: "far fa-times" }, null, -1)
      ]), 8, _hoisted_12)
    ])
  ]))
}