
    import { useI18next } from '@composables/i18next';
    import community from '../community';

    export default {
        name: 'FacilityList',
        props: {
            facilities: {
                type: Array,
                default: () => [],
            },
            hasMap: {
                type: Boolean,
                default: true,
            },
        },

        setup () {
            const { t, vT } = useI18next(['facility_search']);
            return { t, vT };
        },

        data () {
            return {
                facilityID: '',
                maxName: 22,
            };
        },
        computed: {
            isFacilitiesFilledWithResults () {
                return this.facilities.length > 0;
            },
        },
        methods: {

            facilityName (facility) {
                let prettyFacility = facility.data.name.trim();
                if (prettyFacility.length > this.maxName) {
                    prettyFacility = `${prettyFacility.slice(0, this.maxName)}...`;
                }
                return prettyFacility;
            },

            selectFacilityMarker (facility) {
                if (this.facilityID === facility.data.id) {
                    return;
                }

                this.selectFacility(facility.id);
                this.$emit('selectmarker', facility.data.id);
            },

            selectFacility (facilityID) {
                this.facilityID = facilityID;
                this.$emit('scrollto');
            },

            goToFacility (facility) {
                this.$router.push({ path: community.getFacilityPath(facility) });
                this.$emit('close');
            },

            onScroll (event) {
                this.$emit('scroll', event);
            },

        },
    };
