import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-headline" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "font-weight--semibold" }
const _hoisted_4 = ["href", "title"]
const _hoisted_5 = ["title", "aria-label"]
const _hoisted_6 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(`modal entry-confirm ${$props.confirmationFor}`),
      role: "dialog",
      "aria-live": "assertive"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", null, _toDisplayString($setup.t('headline_copy')), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, [
          _createTextVNode(_toDisplayString($setup.t('body_copy_1')) + " ", 1),
          _createElementVNode("a", {
            class: "bold",
            href: `/how-it-works?locale=${_ctx.$store.state.app.userLanguage}`,
            title: $setup.t('learn-more-link'),
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.closeModal()))
          }, _toDisplayString($setup.t('learn-more-link')), 9, _hoisted_4)
        ]),
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, _toDisplayString($setup.t('body_copy_2')), 1)
        ]),
        _createElementVNode("button", {
          id: "entry_confirmed_close_btn",
          class: "entry-confirmed-close-btn",
          title: $setup.t('buttons:close'),
          "aria-label": $setup.t('buttons:close'),
          onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
        }, _toDisplayString($setup.t('buttons:close')), 9, _hoisted_5)
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        title: $setup.t('modal_content:titles.close'),
        onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("i", { class: "fas fa-times" }, null, -1)
      ]), 8, _hoisted_6)
    ], 2)
  ]))
}