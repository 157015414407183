
    import { useI18next } from '@composables/i18next';
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    import { RenderlessValidator } from 'rocketship-validator';
    import { BaseTextarea, BaseTextareaCounter } from '@components/form';
    import { noteEntry as validatorGuards } from '../../../lib/validation/client-validators';
    import uploader from '../uploader';
    import openModal, { EntryConfirmationModal, EntryLimitedModal } from '../modals';
    import { track } from '../plugins/WebAnalytics';
    import BaseModal from './BaseModal';


    export default {
        name: 'NoteFormModal',

        components: {
            RenderlessValidator,
            BaseTextarea,
            BaseTextareaCounter,
        },

        extends: BaseModal,

        setup () {
            const { t, vT } = useI18next(['entry_form', 'buttons', 'modal_content']);
            return { t, vT };
        },

        data () {
            return {
                modalName: 'note-form',
                loading: false,
                max: 100,
                tempForm: {
                    type: 'note',
                    note: '',
                },
            };
        },

        computed: {
            ...mapGetters({
                getFacility: 'app/getFacility',
            }),

            date () {
                return moment().format('M.DD.YY');
            },
            name () {
                return `${this.profile.first_name} ${this.profile?.last_name?.slice(0, 1) || ''}.`;
            },
            charsLeft () {
                return (this.tempForm.note?.trim() || '').length;
            },
        },

        validatorGuards,

        methods: {
            updateCharCount (e) {
                this.tempForm.note = e;
            },

            async doSubmit () {
                const { tempForm } = this;
                const { validator } = this.$refs;

                tempForm.facilityId = this.getFacility.id;
                tempForm.facilityName = this.getFacility.name;
                tempForm.facilityProvince = this.getFacility.state;

                await validator.validate();

                if (!validator.isValid) {
                    return;
                }

                this.loading = true;
                try {
                    await uploader.submit(tempForm);
                    track('submission', { category: `${tempForm.facilityName}, note`  });
                    this.closeModal();
                    openModal(EntryConfirmationModal, { confirmationFor: 'note' });
                }
                catch (error) {
                    this.closeModal();
                    const typeOfError = error?.response?.data?.result?.error;
                    if (typeOfError === 'limited') {
                        openModal(EntryLimitedModal, { type: 'notes' });
                    }
                    else {
                        this.loading = false;
                        this.hasErrors = true;
                    }


                }
            },
        },
    };
