
    import { useI18next } from '@composables/i18next';
    import VideoForm from '@components/VideoForm.vue';
    import { openPopup } from '../popup';
    import BaseModal from './BaseModal';
    import openModal, { EntryConfirmationModal, EntryLimitedModal } from '.';

    export default {
        name: 'NominationVideoModal',

        components: {
            VideoForm,
        },

        extends: BaseModal,
        setup () {
            const { t, vT } = useI18next(['entry_form', 'buttons', 'modal_content']);
            return { t, vT };
        },

        data () {
            return {
                modalName: 'media-form',
                videoParams: {
                    types: 'mp4, mov, ogv, webm, avi, dv, qt, mpg, mpg2, mpg4, 3gp, 3g2, asf, wmv',
                    max: 100 * 1024 * 1024,
                },
            };
        },

        computed: {
            rulesLink () {
                return `<a class="nowrap" href="${this.app.tb2URL}${this.t('links:rules_url')}" target="_blank">${this.t('links:rules_copy')}</a>`;
            },
        },

        methods: {
            openFAQ (event) {
                if (event.target.tagName == 'A') {
                    event.preventDefault();
                    openPopup(event);
                }
            },
            openResults (result) {
                this.closeModal();
                if (result == 'success') {
                    openModal(EntryConfirmationModal);
                }
                else {
                    openModal(EntryLimitedModal, { type: 'story' });
                }
            },
        },
    };
