import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { name: "user_info" }
const _hoisted_2 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseInput = _resolveComponent("BaseInput")
  const _component_RenderlessValidator = _resolveComponent("RenderlessValidator")

  return (_openBlock(), _createBlock(_component_RenderlessValidator, {
    ref: "validator",
    guards: $data.validatorGuards,
    data: $data.tempProfile,
    class: "login-form"
  }, {
    default: _withCtx(({
            hasErrors, isError,
        }) => [
      _createElementVNode("form", {
        id: "login_form",
        name: "login_form",
        autocomplete: "",
        novalidate: "",
        "data-e2e": "login-form",
        onSubmit: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.doLogin && $options.doLogin(...args)), ["prevent"]))
      }, [
        _withDirectives(_createElementVNode("p", {
          class: "error error-message",
          "data-e2e": "login-form-error"
        }, _toDisplayString($setup.t('error_copy')), 513), [
          [_vShow, hasErrors]
        ]),
        _createElementVNode("fieldset", _hoisted_1, [
          _createVNode(_component_BaseInput, {
            modelValue: $data.tempProfile.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.tempProfile.email) = $event)),
            type: $props.hideInputs ? 'hidden' : 'email',
            label: $setup.t('field.email'),
            name: "email",
            "is-error": isError('email'),
            error: $setup.t('ada.errors.email')
          }, null, 8, ["modelValue", "type", "label", "is-error", "error"])
        ]),
        _createElementVNode("button", {
          type: "submit",
          class: "btn btn_login",
          disabled: $data.loading,
          "data-e2e": "login-form-submit"
        }, _toDisplayString($setup.t('buttons:sign_in')), 9, _hoisted_2)
      ], 32)
    ]),
    _: 1
  }, 8, ["guards", "data"]))
}