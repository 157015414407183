import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "name", "placeholder"]


export default /*@__PURE__*/Object.assign({ inheritAttrs: false }, {
  __name: 'BaseInput',
  props: /*@__PURE__*/_mergeModels({
        isError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            required: true,
        },
    }, {
    "modelValue": { type: String, required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

    
    

    const model = _useModel(__props, "modelValue");

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-input field", { error: __props.isError }])
  }, [
    _createElementVNode("label", { for: __props.name }, _toDisplayString(__props.label), 9, _hoisted_1),
    _withDirectives(_createElementVNode("input", _mergeProps({
      id: __props.name,
      ref: `input_${_ctx._uid}`,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((model).value = $event))
    }, _ctx.$attrs, {
      name: __props.name,
      placeholder: __props.placeholder || __props.label
    }), null, 16, _hoisted_2), [
      [_vModelDynamic, model.value]
    ])
  ], 2))
}
}

})