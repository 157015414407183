import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "modal add-facility-modal",
  role: "dialog",
  "aria-live": "assertive"
}
const _hoisted_2 = { class: "modal-headline" }
const _hoisted_3 = {
  id: "facility_form_title",
  class: "add-facility-modal-headline"
}
const _hoisted_4 = { class: "modal-content facility-modal-content" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "error",
  role: "alert",
  "aria-live": "assertive"
}
const _hoisted_7 = { name: "facility-info" }
const _hoisted_8 = { class: "add-facility-form" }
const _hoisted_9 = { class: "fac-row" }
const _hoisted_10 = { class: "fac-row" }
const _hoisted_11 = ["title", "aria-label"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseInput = _resolveComponent("BaseInput")
  const _component_BaseSelect = _resolveComponent("BaseSelect")
  const _component_RenderlessValidator = _resolveComponent("RenderlessValidator")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[6] || (_cache[6] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString($setup.t('add_facility:headline')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "font-weight--semibold",
          innerHTML: $setup.t('add_facility:body')
        }, null, 8, _hoisted_5),
        _createVNode(_component_RenderlessValidator, {
          ref: "validator",
          guards: _ctx.$options.validatorGuards,
          data: $data.tempFacility,
          class: "faci-form"
        }, {
          default: _withCtx(({
                        hasErrors, isError,
                    }) => [
            _createElementVNode("form", {
              id: "facility_form",
              class: "add-facility-modal-form",
              autocomplete: "on",
              novalidate: "",
              "aria-labelledby": "facility_form_title",
              role: "form",
              onSubmit: _cache[4] || (_cache[4] = _withModifiers((...args) => ($options.addFacility && $options.addFacility(...args)), ["prevent"]))
            }, [
              hasErrors
                ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString($setup.t('add_facility:error')), 1))
                : _createCommentVNode("", true),
              _createElementVNode("fieldset", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_BaseInput, {
                      modelValue: $data.tempFacility.name,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.tempFacility.name) = $event)),
                      label: $setup.t('add_facility:form_fields.arena_name'),
                      error: $setup.t('add_facility:error_messages.name'),
                      name: "name",
                      "is-error": isError('name')
                    }, null, 8, ["modelValue", "label", "error", "is-error"]),
                    _createVNode(_component_BaseInput, {
                      modelValue: $data.tempFacility.city,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.tempFacility.city) = $event)),
                      label: $setup.t('add_facility:form_fields.city'),
                      error: $setup.t('add_facility:error_messages.city'),
                      name: "city",
                      "is-error": isError('city')
                    }, null, 8, ["modelValue", "label", "error", "is-error"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_BaseSelect, {
                      modelValue: $data.tempFacility.state,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.tempFacility.state) = $event)),
                      name: "state",
                      label: $setup.t('add_facility:form_fields.province'),
                      error: $setup.t('add_facility:error_messages.state'),
                      "select-one": '',
                      "is-error": isError('state'),
                      options: $options.stateOptions,
                      class: "province"
                    }, null, 8, ["modelValue", "label", "error", "is-error", "options"]),
                    _createVNode(_component_BaseInput, {
                      id: "zip_div",
                      modelValue: $data.tempFacility.zip,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.tempFacility.zip) = $event)),
                      class: "zip_div",
                      label: $setup.t('add_facility:form_fields.postal_code'),
                      error: $setup.t('add_facility:error_messages.zip'),
                      name: "zip",
                      "is-error": isError('zip')
                    }, null, 8, ["modelValue", "label", "error", "is-error"])
                  ])
                ])
              ]),
              _createElementVNode("button", {
                class: "btn submit-button",
                type: "submit",
                title: $setup.t('add_facility:cta'),
                "aria-label": $setup.t('add_facility:cta')
              }, _toDisplayString($setup.t('add_facility:cta')), 9, _hoisted_11)
            ], 32)
          ]),
          _: 1
        }, 8, ["guards", "data"]),
        _createElementVNode("div", {
          class: "legalese",
          innerHTML: $setup.t('add_facility:legalese')
        }, null, 8, _hoisted_12)
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        title: $setup.t('modal_content:titles.close'),
        onClick: _cache[5] || (_cache[5] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[7] || (_cache[7] = [
        _createElementVNode("i", { class: "far fa-times" }, null, -1)
      ]), 8, _hoisted_13)
    ])
  ]))
}