
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'SessionExpirationModal',

        extends: BaseModal,

        setup () {
            const { t, vT } = useI18next(['session', 'buttons', 'modal_content']);
            return { t, vT };
        },

        data () {
            return {
                modalName: 'session-expiration',
            };
        },
    };
