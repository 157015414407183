

    import videojs from 'video.js';

    export default {
        name: 'VideoPlayer',

        props: {
            options: {
                type: Object,
                default () {
                    return {};
                },
                required: true,
            },
        },

        data () {
            return {
                player: null,
                videoOptions: {
                    preload: 'auto',
                    controls: true,
                    controlsList: 'nodownload',
                    oncontextmenu: 'return false;',
                    fluid: true,
                },
            };
        },

        mounted () {
            const newOptions = { ...this.videoOptions, ...this.options };
            this.player = videojs(this.$refs.videoPlayer, newOptions, () => {
                this.player.log('onPlayerReady', this);
            });
        },

        beforeUnmount () {
            if (this.player) {
                this.player.dispose();
            }
        },

        methods: {
            emitPlay () {
                this.$emit('play');
            },
        },
    };

