
    import { useI18next } from '@composables/i18next';
    import { mapGetters, mapState } from 'vuex';
    import { Splide, SplideSlide } from '@splidejs/vue-splide'; // https://splidejs.com/integration/vue-splide/
    import openModal, { LoginModal, FindApprovedFacilityModal } from '../modals';
    import { track } from '../plugins/WebAnalytics';
    import BaseModal from './BaseModal';

    export default {
        name: 'OnboardingModal',

        components: {
            Splide,
            SplideSlide,
        },

        extends: BaseModal,

        setup () {
            const { t, vT } = useI18next(['modal_content']);
            return { t, vT };
        },

        data () {
            return {
                settings: {
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                adaptiveHeight: false,
                            },
                        },
                        {
                            breakpoint: 750,
                            settings: {
                                adaptiveHeight: true,
                            },
                        },
                        {
                            breakpoint: 320,
                            settings: {
                                adaptiveHeight: true,
                            },
                        },
                    ],
                },
                currentSlideOnBoarding: 0,
                modalName: 'onboarding',
            };
        },

        computed: {
            ...mapGetters({
                isLoggedIn: 'profile/loggedIn',
            }),
            ...mapState(['app', 'profile']),
        },

        methods: {
            slickNext () {
                const { splide } = this.$refs.splide; // Access the Splide instance
                if (splide) {
                    splide.go('+1'); // Move to the next slide
                }
                else {
                    console.error('Splide instance not found');
                }
            },
            openLoginModal () {
                if (!this.isLoggedIn) {
                    openModal(LoginModal);
                }
                this.closeModal();
            },
            updateSlideOnboarding (newIndex, prevIndex, destIndex) {
                this.currentSlideOnBoarding = prevIndex;
                track('onboarding', { category: `slide-${this.prevIndex + 1}`  });
                console.log(this.currentSlideOnBoarding);
            },
            openFindApprovedFacilityModal () {
                openModal(FindApprovedFacilityModal);
            },

            findArenaClick () {
                this.trackUrchinHeader('click', 'Onboarding Find Arena');
                this.openFindApprovedFacilityModal();
                this.closeModal();
            },

            howItWorksClick () {
                this.trackUrchinHeader('click', 'Onboarding Learn More');
                this.closeModal();
            },

            trackUrchinHeader (action, category) {
                track(action, { category });
            },
        },
    };
