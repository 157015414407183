
    import { useI18next } from '@composables/i18next';
    import RegisterForm from '@components/RegisterForm';
    import BaseModal from './BaseModal';

    export default {
        name: 'RegistrationModal',
        components: {
            RegisterForm,
        },

        extends: BaseModal,

        setup () {
            const { t, vT } = useI18next(['registration', 'modal_content']);
            return { t, vT };
        },

        data () {
            return {
                modalName: 'register',
            };
        },

    };
