import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "header-cta"
}
const _hoisted_2 = { class: "internal-text my_arena_text" }
const _hoisted_3 = ["data-e2e", "title"]
const _hoisted_4 = { class: "internal-text" }
const _hoisted_5 = {
  key: 1,
  class: "header-cta"
}
const _hoisted_6 = ["data-e2e", "title"]
const _hoisted_7 = { class: "internal-text" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link")
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", null, [
    ($props.isLoggedIn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", {
            "data-e2e": "header-logged-in",
            class: _normalizeClass({ 'no-arena': !$options.hasFavoriteFacility })
          }, [
            _createVNode(_component_router_link, {
              to: `/community/${_ctx.profile.my_facility}`,
              class: "arena-link",
              title: $setup.t('links:my_arena_title'),
              role: "menuitem",
              onClick: _cache[0] || (_cache[0] = $event => ($options.trackUrchinHeaderLink('navigate', 'my-arena')))
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("span", _hoisted_2, null, 512), [
                  [_directive_t, 'links:my_arena']
                ])
              ]),
              _: 1
            }, 8, ["to", "title"]),
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "header-cta-divider" }, "|", -1)),
            _createElementVNode("a", {
              "data-e2e": $props.endToEndLogout,
              href: "#",
              title: $setup.t('links:sign_out'),
              role: "menuitem",
              onClick: _cache[1] || (_cache[1] = $event => {_ctx.logOut(); $options.trackUrchinHeaderLink('click', 'log-out')})
            }, [
              _createElementVNode("span", _hoisted_4, _toDisplayString($setup.t('links:sign_out')), 1)
            ], 8, _hoisted_3)
          ], 2)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("a", {
            href: "#",
            class: "header-cta-login",
            "data-e2e": $props.endToEnd,
            title: $setup.t('links:reg_login'),
            role: "menuitem",
            onClick: _cache[2] || (_cache[2] = $event => {$options.openLoginModal(); $options.trackUrchinHeaderLink('navigate', 'login')})
          }, [
            _cache[4] || (_cache[4] = _createElementVNode("i", { class: "far fa-user-circle" }, null, -1)),
            _createElementVNode("span", _hoisted_7, _toDisplayString($setup.t('links:reg_login')), 1)
          ], 8, _hoisted_6)
        ]))
  ]))
}