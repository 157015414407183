import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "video-form" }
const _hoisted_2 = {
  key: 0,
  class: "error error-message"
}
const _hoisted_3 = {
  key: 2,
  class: "preview-container"
}
const _hoisted_4 = { id: "submit" }
const _hoisted_5 = { class: "file-name" }
const _hoisted_6 = { class: "or" }
const _hoisted_7 = {
  key: 0,
  class: "error error-message"
}
const _hoisted_8 = {
  key: 1,
  class: "canadian-email"
}
const _hoisted_9 = { class: "submit" }
const _hoisted_10 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseFileInput = _resolveComponent("BaseFileInput")
  const _component_BaseInput = _resolveComponent("BaseInput")
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")
  const _component_Validator = _resolveComponent("Validator")
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      id: "video_form",
      name: "video_form",
      autocomplete: "",
      novalidate: "",
      "data-e2e": "video-form",
      onSubmit: _cache[2] || (_cache[2] = _withModifiers((...args) => ($options.submitVideo && $options.submitVideo(...args)), ["prevent"]))
    }, [
      (($data.videoError || $data.defaultError))
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($setup.t(`photo_video.video.errors.${$data.videoError}`) || $setup.t('photo_video.video.errors.default')), 1))
        : _createCommentVNode("", true),
      (!$options.isVideoSelected)
        ? (_openBlock(), _createBlock(_component_BaseFileInput, {
            key: 1,
            modelValue: $data.tempVideo.video,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.tempVideo.video) = $event)),
            name: "video",
            label: $setup.t('photo_video.video.video_label'),
            accept: $props.params.types,
            "button-label": $setup.t('select_video'),
            "max-file-size": $props.params.max,
            "is-error": $data.videoError != '',
            error: $setup.t(`photo_video.video.errors.${$data.videoError}`) || $setup.t('photo_video.video.errors.default'),
            onSelected: $options.selectVideo
          }, null, 8, ["modelValue", "label", "accept", "button-label", "max-file-size", "is-error", "error", "onSelected"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString($data.tempVideo.name), 1),
              _createElementVNode("p", null, [
                _createElementVNode("a", {
                  href: "#",
                  onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.anotherVideo && $options.anotherVideo(...args)), ["prevent"]))
                }, _toDisplayString($setup.t('photo_video.video.select_another')), 1)
              ])
            ])
          ]))
    ], 32),
    _withDirectives(_createElementVNode("p", _hoisted_6, null, 512), [
      [_directive_t, 'entry_form:video.or']
    ]),
    _createVNode(_component_Validator, {
      ref: "validator",
      guards: _ctx.$options.validatorGuards,
      data: $data.tempYoutube
    }, {
      default: _withCtx(({
                hasErrors, isError,
            }) => [
        _createElementVNode("form", {
          id: "note_form",
          name: "note_form",
          autocomplete: "",
          novalidate: "",
          "data-e2e": "note-form",
          onSubmit: _cache[5] || (_cache[5] = _withModifiers((...args) => (_ctx.submitYoutube && _ctx.submitYoutube(...args)), ["prevent"]))
        }, [
          hasErrors
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString($setup.t('photo_video.video.errors.youtube')), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_BaseInput, {
            id: "youtube_text_field",
            modelValue: $data.tempYoutube.video,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.tempYoutube.video) = $event)),
            class: "youtube_label",
            label: $setup.t('photo_video.video.youtube_label'),
            error: $setup.t('photo_video.video.errors.youtube'),
            name: "youtube",
            placeholder: "",
            "is-error": isError('video')
          }, null, 8, ["modelValue", "label", "error", "is-error"]),
          _createVNode(_component_BaseCheckbox, {
            modelValue: $data.tempYoutube.rules,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.tempYoutube.rules) = $event)),
            name: 'originalWork',
            label: $setup.t('photo_video.photo.original_work_checkbox'),
            "is-error": isError('rules'),
            error: $setup.t('photo.original_work_checkbox')
          }, null, 8, ["modelValue", "label", "is-error", "error"]),
          ($props.essay)
            ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                _createElementVNode("i", null, _toDisplayString($setup.t('essay.canadian_email')), 1)
              ]))
            : _createCommentVNode("", true)
        ], 32)
      ]),
      _: 1
    }, 8, ["guards", "data"]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("button", {
        id: "video_submit_btn",
        class: "video-submit-btn btn btn_submit",
        type: "submit",
        disabled: !$options.enabledButton,
        "data-e2e": "video-form-submit",
        onClick: _cache[6] || (_cache[6] = (...args) => ($options.submitVideo && $options.submitVideo(...args)))
      }, _toDisplayString($setup.t('buttons:submit')), 9, _hoisted_10)
    ])
  ]))
}