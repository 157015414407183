
    import { useI18next } from '@composables/i18next';
    import PhotoForm from '@components/PhotoForm';
    import openModal, { EntryConfirmationModal, EntryLimitedModal  } from '../modals';
    import BaseModal from './BaseModal';

    export default {
        name: 'MediaFormModal',

        components: {
            PhotoForm,
        },

        extends: BaseModal,
        setup () {
            const { t, vT } = useI18next(['entry_form', 'buttons', 'modal_content']);
            return { t, vT };
        },

        data () {
            return {
                modalName: 'media-form',
                photoParams: {
                    types: 'jpg, jpeg, png, gif',
                    max: 100 * 1024 * 1024,
                },
                previewImage: '',
            };
        },

        methods: {

            setPreview (img) {
                this.previewImage = img;
            },

            openResults (result) {
                this.closeModal();
                if (result == 'success') {
                    openModal(EntryConfirmationModal);
                }
                else {
                    openModal(EntryLimitedModal, { type: 'photo_video' });
                }
            },
        },
    };
