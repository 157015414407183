import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal limited-modal",
  role: "dialog",
  "aria-live": "assertive"
}
const _hoisted_2 = { class: "modal-headline" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["href", "title", "aria-label"]
const _hoisted_6 = ["title", "aria-label", "innerHTML"]
const _hoisted_7 = ["title", "aria-label"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString($setup.t(`${$props.type}.limited_headline_copy`)), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", {
          innerHTML: $setup.t(`${$props.type}.limited_body_copy_1`)
        }, null, 8, _hoisted_4),
        _createElementVNode("p", null, [
          _createTextVNode(_toDisplayString($setup.t(`${$props.type}.limited_body_copy_2`)) + " ", 1),
          _createElementVNode("a", {
            href: `/how-it-works?locale=${_ctx.$store.state.app.userLanguage}`,
            title: $setup.t('learn-more-link'),
            "aria-label": $setup.t('learn-more-link'),
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.closeModal()))
          }, _toDisplayString($setup.t('learn-more-link')), 9, _hoisted_5)
        ]),
        _createElementVNode("button", {
          class: "limited-modal-close-btn",
          title: $setup.t('buttons:close_title'),
          "aria-label": $setup.t('buttons:close_title'),
          onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["prevent"])),
          innerHTML: $setup.t('buttons:close_title')
        }, null, 8, _hoisted_6)
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        title: $setup.t('modal_content:titles.close'),
        "aria-label": $setup.t('modal_content:titles.close'),
        onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("i", { class: "far fa-times" }, null, -1)
      ]), 8, _hoisted_7)
    ])
  ]))
}