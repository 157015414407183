import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/find-my-arena-modal.png'
import _imports_1 from '@public/img/naminate-modal.png'
import _imports_2 from '@public/img/earn-rally-poin-modal.png'


const _hoisted_1 = {
  class: "modal onboarding-modal",
  role: "dialog",
  "aria-live": "assertive"
}
const _hoisted_2 = { class: "modal-headline" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "slide-container" }
const _hoisted_6 = { class: "slide-description" }
const _hoisted_7 = ["alt"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["title", "aria-label"]
const _hoisted_11 = { class: "slide-container" }
const _hoisted_12 = { class: "slide-description" }
const _hoisted_13 = ["alt"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["title", "aria-label"]
const _hoisted_17 = { class: "slide-container" }
const _hoisted_18 = { class: "slide-description" }
const _hoisted_19 = ["alt"]
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = ["title"]
const _hoisted_23 = ["title", "aria-label"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SplideSlide = _resolveComponent("SplideSlide")
  const _component_router_link = _resolveComponent("router-link")
  const _component_Splide = _resolveComponent("Splide")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", {
          class: "modal-headline",
          innerHTML: $setup.t('onboarding.headline_copy')
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", {
        id: _ctx.app.locale,
        class: _normalizeClass(["modal-content", `current-${$data.currentSlideOnBoarding}`])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["carousel-container", `current-${$data.currentSlideOnBoarding}`])
        }, [
          _createVNode(_component_Splide, _mergeProps({
            ref: "splide",
            class: "carousel-wrapper"
          }, $data.settings, {
            arrows: true,
            "center-mode": true,
            "center-padding": '0px',
            dots: false,
            "focus-on-select": true,
            infinite: true,
            "slides-to-show": 1,
            "slides-to-scroll": 1,
            "onSplide:moved": $options.updateSlideOnboarding
          }), {
            default: _withCtx(() => [
              _createVNode(_component_SplideSlide, {
                inert: $data.currentSlideOnBoarding !== 0,
                class: "carousel-slides slide-1"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("img", {
                        class: "find-my-arena",
                        src: _imports_0,
                        alt: $setup.t('onboarding.slide_1.headline_copy')
                      }, null, 8, _hoisted_7),
                      _createElementVNode("h2", {
                        class: "modal-subheadline",
                        innerHTML: $setup.t('onboarding.slide_1.headline_copy')
                      }, null, 8, _hoisted_8),
                      _createElementVNode("span", {
                        class: "body-copy",
                        innerHTML: $setup.t('onboarding.slide_1.body_copy')
                      }, null, 8, _hoisted_9),
                      _createElementVNode("button", {
                        title: $setup.t('buttons:next'),
                        "aria-label": $setup.t('buttons:next'),
                        onClick: _cache[0] || (_cache[0] = $event => ($options.slickNext()))
                      }, _toDisplayString($setup.t('buttons:next')), 9, _hoisted_10)
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["inert"]),
              _createVNode(_component_SplideSlide, {
                inert: $data.currentSlideOnBoarding !== 1,
                class: "carousel-slides slide-2"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("img", {
                        class: "find-my-arena",
                        src: _imports_1,
                        alt: $setup.t('onboarding.slide_2.headline_copy')
                      }, null, 8, _hoisted_13),
                      _createElementVNode("h2", {
                        class: "modal-subheadline",
                        innerHTML: $setup.t('onboarding.slide_2.headline_copy')
                      }, null, 8, _hoisted_14),
                      _createElementVNode("span", {
                        class: "body-copy",
                        innerHTML: $setup.t('onboarding.slide_2.body_copy')
                      }, null, 8, _hoisted_15),
                      _createElementVNode("button", {
                        title: $setup.t('buttons:next'),
                        "aria-label": $setup.t('buttons:next'),
                        onClick: _cache[1] || (_cache[1] = $event => ($options.slickNext()))
                      }, _toDisplayString($setup.t('buttons:next')), 9, _hoisted_16)
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["inert"]),
              _createVNode(_component_SplideSlide, {
                inert: $data.currentSlideOnBoarding !== 2,
                class: "carousel-slides slide-3"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("img", {
                        class: "find-my-arena",
                        src: _imports_2,
                        alt: $setup.t('onboarding.slide_3.headline_copy')
                      }, null, 8, _hoisted_19),
                      _createElementVNode("h2", {
                        class: "modal-subheadline",
                        innerHTML: $setup.t('onboarding.slide_3.headline_copy')
                      }, null, 8, _hoisted_20),
                      _createElementVNode("span", {
                        class: "body-copy",
                        innerHTML: $setup.t('onboarding.slide_3.body_copy')
                      }, null, 8, _hoisted_21),
                      _createElementVNode("a", {
                        id: "onboarding_find_my_arena",
                        href: "#",
                        class: "button onboarding-find-my-arena-btn",
                        title: $setup.t('links:find_facility'),
                        onClick: _cache[2] || (_cache[2] = _withModifiers((...args) => ($options.findArenaClick && $options.findArenaClick(...args)), ["prevent"]))
                      }, [
                        _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fas fa-search" }, null, -1)),
                        _createTextVNode(" " + _toDisplayString($setup.t('links:find_facility')), 1)
                      ], 8, _hoisted_22),
                      _createVNode(_component_router_link, {
                        class: "button onboarding-learn-more-btn",
                        to: "/how-it-works",
                        title: $setup.t('links:how_it_works'),
                        onClick: $options.howItWorksClick
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('buttons:learn_more')), 1)
                        ]),
                        _: 1
                      }, 8, ["title", "onClick"])
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["inert"])
            ]),
            _: 1
          }, 16, ["onSplide:moved"])
        ], 2)
      ], 10, _hoisted_4),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        title: $setup.t('modal_content:titles.close'),
        "aria-label": $setup.t('modal_content:titles.close'),
        onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("i", { class: "fas fa-times" }, null, -1)
      ]), 8, _hoisted_23)
    ])
  ]))
}