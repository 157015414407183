import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/footer/kraftheinz-logo.png'
import _imports_1 from '@public/img/footer/philadelphia-logo.png'
import _imports_2 from '@public/img/footer/maxwell-house-logo.png'
import _imports_3 from '@public/img/footer/crave-logo.png'
import _imports_4 from '@public/img/footer/kpb-logo.png'
import _imports_5 from '@public/img/footer/kd-logo.png'
import _imports_6 from '@public/img/footer/heinz-logo.png'


const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "footer-wrapper" }
const _hoisted_3 = { class: "footer-content-wrapper" }
const _hoisted_4 = { class: "footer-links" }
const _hoisted_5 = ["href", "title"]
const _hoisted_6 = ["href", "title"]
const _hoisted_7 = ["href", "title"]
const _hoisted_8 = { class: "kraft-logo" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "footer-kraft-logos-right" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "footer-sponsors-logo" }
const _hoisted_13 = ["href", "title"]
const _hoisted_14 = ["href", "title"]
const _hoisted_15 = ["alt"]
const _hoisted_16 = ["href", "title"]
const _hoisted_17 = ["src", "alt"]
const _hoisted_18 = { class: "footer-disclaimer" }
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = { class: "footer-brands-logos" }
const _hoisted_21 = ["href", "title"]
const _hoisted_22 = ["alt"]
const _hoisted_23 = ["href", "title"]
const _hoisted_24 = ["alt"]
const _hoisted_25 = ["href", "title"]
const _hoisted_26 = ["alt"]
const _hoisted_27 = ["href", "title"]
const _hoisted_28 = ["alt"]
const _hoisted_29 = ["href", "title"]
const _hoisted_30 = ["alt"]
const _hoisted_31 = ["href", "title"]
const _hoisted_32 = ["alt"]
const _hoisted_33 = { class: "kraft-footer" }
const _hoisted_34 = ["href", "title"]
const _hoisted_35 = ["href", "title"]
const _hoisted_36 = ["href", "title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HockeyvilleLogo = _resolveComponent("HockeyvilleLogo")
  const _component_NHLLogos = _resolveComponent("NHLLogos")
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: _ctx.app.tb2URL + $setup.t('links:rules_url'),
              target: "_blank",
              title: `${$setup.t('rules_copy')} ${$setup.t('pdf')}`
            }, _toDisplayString($setup.t('links:rules_copy')), 9, _hoisted_5)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: `/faq?locale=${_ctx.$store.state.app.userLanguage}`,
              title: `${$setup.t('faq_copy')} ${$setup.t('popup')}`,
              onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.openPopup && $options.openPopup(...args)), ["prevent"]))
            }, _toDisplayString($setup.t('links:faq_copy')), 9, _hoisted_6)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: `/dmca?locale=${_ctx.$store.state.app.userLanguage}`,
              title: `${$setup.t('links.copyright_infringement_policy_copy')} ${$setup.t('popup')}`,
              onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.openPopup && $options.openPopup(...args)), ["prevent"]))
            }, _toDisplayString($setup.t('links.copyright_infringement_policy_copy')), 9, _hoisted_7)
          ])
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["footer-kraft-logos", { maint: _ctx.ui.pageName === 'maintenance' }])
      }, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("a", {
            href: _ctx.app.tb2URL + (_ctx.isFr ? $setup.t('links.kraft_logo_fr_url') : $setup.t('links.kraft_logo_url')),
            target: "_blank"
          }, [
            _createVNode(_component_HockeyvilleLogo)
          ], 8, _hoisted_9)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("h5", {
            innerHTML: $setup.t('brought_copy')
          }, null, 8, _hoisted_11),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("a", {
              href: _ctx.app.tb2URL + $setup.t('links.nhl_link'),
              target: "_blank",
              title: `${$setup.t('links:titles.nhl')} ${$setup.t('new_window')}`
            }, [
              _createVNode(_component_NHLLogos, { class: "nhl-badge-logo" })
            ], 8, _hoisted_13),
            _createElementVNode("a", {
              href: _ctx.app.tb2URL + $setup.t('links.kraft_heinz_link'),
              target: "_blank",
              title: `${$setup.t('links:titles.kraft_heinz')} ${$setup.t('new_window')}`
            }, [
              _createElementVNode("img", {
                src: _imports_0,
                class: "kraftheinz-logo",
                alt: $setup.t('links:titles.kraft_heinz')
              }, null, 8, _hoisted_15)
            ], 8, _hoisted_14),
            _createElementVNode("a", {
              href: _ctx.app.tb2URL + $setup.t('links.nhlpa_link'),
              target: "_blank",
              title: `${$setup.t('links:titles.nhlpa')} ${$setup.t('new_window')}`
            }, [
              _createElementVNode("img", {
                class: "nhlpa-logo",
                src: _ctx.$i18nAsset('img/footer/logo-nhlpa-footer.png'),
                alt: $setup.t('links:titles.nhlpa')
              }, null, 8, _hoisted_17)
            ], 8, _hoisted_16)
          ])
        ])
      ], 2),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("p", {
          innerHTML: $setup.t('footer:disclaimer', { tb2URL: _ctx.app.tb2URL })
        }, null, 8, _hoisted_19)
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("a", {
          href: _ctx.app.tb2URL + $setup.t('links.philadelphia_url'),
          title: `${$setup.t('links:titles.philadelphia')} ${$setup.t('new_window')}`,
          target: "_blank"
        }, [
          _createElementVNode("img", {
            src: _imports_1,
            class: "philadelphia-logo",
            alt: $setup.t('links:titles.philadelphia')
          }, null, 8, _hoisted_22)
        ], 8, _hoisted_21),
        _createElementVNode("a", {
          href: _ctx.app.tb2URL + $setup.t('links.maxwell_house_url'),
          title: `${$setup.t('links:titles.maxwell_house')} ${$setup.t('new_window')}`,
          target: "_blank"
        }, [
          _createElementVNode("img", {
            src: _imports_2,
            class: "maxwell-house-logo",
            alt: $setup.t('links:titles.maxwell_house')
          }, null, 8, _hoisted_24)
        ], 8, _hoisted_23),
        _createElementVNode("a", {
          href: _ctx.app.tb2URL + $setup.t('links.crave_url'),
          title: `${$setup.t('links:titles.crave')} ${$setup.t('new_window')}`,
          target: "_blank"
        }, [
          _createElementVNode("img", {
            src: _imports_3,
            class: "crave-logo",
            alt: $setup.t('links:titles.crave')
          }, null, 8, _hoisted_26)
        ], 8, _hoisted_25),
        _createElementVNode("a", {
          href: _ctx.app.tb2URL + $setup.t('links.peanut_butter_url'),
          title: `${$setup.t('links:titles.kraft_peanut_butter')} ${$setup.t('new_window')}`,
          target: "_blank"
        }, [
          _createElementVNode("img", {
            src: _imports_4,
            class: "kpb-logo",
            alt: $setup.t('links:titles.kraft_peanut_butter')
          }, null, 8, _hoisted_28)
        ], 8, _hoisted_27),
        _createElementVNode("a", {
          href: _ctx.app.tb2URL + $setup.t('links.kraft_dinner_url'),
          title: `${$setup.t('links:titles.kraft_dinner')} ${$setup.t('new_window')}`,
          target: "_blank"
        }, [
          _createElementVNode("img", {
            src: _imports_5,
            class: "kd-logo",
            alt: $setup.t('links:titles.kraft_dinner')
          }, null, 8, _hoisted_30)
        ], 8, _hoisted_29),
        _createElementVNode("a", {
          href: _ctx.app.tb2URL + $setup.t('links.heinz_ketchup_url'),
          title: `${$setup.t('links:titles.heinz_ketchup')} ${$setup.t('new_window')}`,
          target: "_blank"
        }, [
          _createElementVNode("img", {
            src: _imports_6,
            class: "heinz-logo",
            alt: $setup.t('links:titles.heinz_ketchup')
          }, null, 8, _hoisted_32)
        ], 8, _hoisted_31)
      ])
    ]),
    _createElementVNode("div", _hoisted_33, [
      _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1)),
      _withDirectives(_createElementVNode("p", null, null, 512), [
        [_directive_t, 'footer:copyright_disclaimer']
      ]),
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: $setup.t('footer:links.contact_url'),
            title: `${$setup.t('links.contact_copy')} ${$setup.t('new_window')}`,
            target: "_blank"
          }, _toDisplayString($setup.t('links.contact_copy')), 9, _hoisted_34)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: _ctx.app.tb2URL + $setup.t('links.terms_of_use_url'),
            title: `${$setup.t('links.terms_of_use')} ${$setup.t('new_window')}`
          }, _toDisplayString($setup.t('links.terms_of_use')), 9, _hoisted_35)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: _ctx.app.tb2URL + $setup.t('links:privacy_url'),
            title: `${$setup.t('links.privacy')} ${$setup.t('new_window')}`,
            target: "_blank"
          }, _toDisplayString($setup.t('links.privacy')), 9, _hoisted_36)
        ])
      ])
    ])
  ]))
}