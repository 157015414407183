import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "language-toggle" }
const _hoisted_2 = ["href", "title", "innerHTML"]
const _hoisted_3 = ["href", "title", "innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: `${_ctx.app.siteURL}?locale=en`,
      class: _normalizeClass(["nav-lang-toggle toggle-en", { active: _ctx.app.userLanguage === 'en' }]),
      title: `${$setup.t('links:language_toggle')} ${$setup.t('global:titles.en')}`,
      onClick: _cache[0] || (_cache[0] = $event => ($options.trackUrchinHeader('language-toggle', 'navigate'))),
      innerHTML: $setup.t('global:en')
    }, null, 10, _hoisted_2),
    _createElementVNode("a", {
      href: `${_ctx.app.siteURL}?locale=fr`,
      class: _normalizeClass(["nav-lang-toggle toggle-fr", { active: _ctx.app.userLanguage === 'fr' }]),
      title: `${$setup.t('links:language_toggle')} ${$setup.t('global:titles.fr')}`,
      onClick: _cache[1] || (_cache[1] = $event => ($options.trackUrchinHeader('language-toggle', 'navigate'))),
      innerHTML: $setup.t('global:fr')
    }, null, 10, _hoisted_3)
  ]))
}