import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "name", "value"]
const _hoisted_3 = {
  key: 0,
  class: "error-message aca-error-message",
  role: "alert",
  "aria-live": "assertive"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-textarea field", { error: $props.isError }])
  }, [
    _createElementVNode("label", { for: $props.name }, _toDisplayString($props.label), 9, _hoisted_1),
    _createElementVNode("textarea", _mergeProps({
      id: $props.name,
      ref: `input_${_ctx._uid}`
    }, _ctx.$attrs, {
      name: $props.name,
      value: $data.textAreaValue,
      onInput: _cache[0] || (_cache[0] = (...args) => ($options.onTextareaUpdate && $options.onTextareaUpdate(...args)))
    }), null, 16, _hoisted_2),
    ($props.isError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($props.error), 1))
      : _createCommentVNode("", true)
  ], 2))
}