import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "social-icons-wrapper" }
const _hoisted_2 = { class: "social-heading" }
const _hoisted_3 = ["href", "title"]
const _hoisted_4 = ["href", "title"]
const _hoisted_5 = ["href", "title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString($setup.t('follow_us')) + " ", 1),
      _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fas fa-angle-right" }, null, -1))
    ]),
    _createElementVNode("a", {
      class: "social-link",
      href: _ctx.app.tb2URL + $setup.t('facebook_site'),
      target: "_blank",
      title: $setup.t('titles.facebook'),
      role: "menuitem",
      onClick: _cache[0] || (_cache[0] = $event => ($options.trackUrchinSocial('header-facebook-follow', 'exit')))
    }, _cache[4] || (_cache[4] = [
      _createElementVNode("i", { class: "fab fa-facebook" }, null, -1)
    ]), 8, _hoisted_3),
    _createElementVNode("a", {
      class: "social-link",
      href: _ctx.app.tb2URL + $setup.t('instagram_site'),
      target: "_blank",
      title: $setup.t('titles.instagram'),
      role: "menuitem",
      onClick: _cache[1] || (_cache[1] = $event => ($options.trackUrchinSocial('header-instagram-follow', 'exit')))
    }, _cache[5] || (_cache[5] = [
      _createElementVNode("i", { class: "fab fa-instagram" }, null, -1)
    ]), 8, _hoisted_4),
    _createElementVNode("a", {
      class: "social-link",
      href: _ctx.app.tb2URL + $setup.t('tiktok_site'),
      target: "_blank",
      title: $setup.t('titles.tiktok'),
      role: "menuitem",
      onClick: _cache[2] || (_cache[2] = $event => ($options.trackUrchinSocial('header-tiktok-follow', 'exit')))
    }, _cache[6] || (_cache[6] = [
      _createElementVNode("i", { class: "fab fa-tiktok" }, null, -1)
    ]), 8, _hoisted_5)
  ]))
}