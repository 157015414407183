
    import { useI18next } from '@composables/i18next';
    import { mapActions } from 'vuex';
    import store from '../store/index';
    import { setRecaptchaV3Challenge } from '../recaptcha';
    import BaseModal from './BaseModal';
    import openModal, { ErrorModal } from './index';

    export default {
        name: 'DuplicateAccountModal',

        extends: BaseModal,
        i18nOptions: { namespaces: ['ineligible', 'modal_content', 'buttons'] },
        props: {
            // Note: if you pull these values from copydeck, you need to make
            // sure the namespace is loaded before we need this component, ideally at
            // app startup, since the user may not have a viable Internet connection
            // when this modal appears.
            headline: {
                type: String,
                default: 'Duplicate',
            },
            body: {
                type: String,
                default: 'Please try again later.',
            },
            tempProfile: {
                type: Object,
                required: true,
            },
            buttonLabel: {
                type: String,
                default: 'SIGN IN',
            },
        },

        setup () {
            const { t, vT } = useI18next(['ineligible', 'modal_content', 'buttons']);
            return { t, vT };
        },

        data () {
            return {
                modalName: 'duplicate',
                loading: false,
            };
        },
        methods: {
            ...mapActions({
                logIn: 'profile/logIn',
            }),

            async doLogin () {
                const { tempProfile } = this;

                tempProfile.x_channel = store.state.app.channel || undefined;

                await setRecaptchaV3Challenge({ data: tempProfile, action: 'login' });

                this.loading = true;

                try {
                    await this.logIn({ tempProfile });
                    this.loading = false;
                    this.$emit('logged-in');
                }
                catch (error) {
                    this.loading = false;

                    const { response } = error;

                    if (response && response.status === 404) {
                        this.updateProfile(tempProfile);
                        this.$emit('not-found');
                    }
                    else if (response && response.data && response.data.errors) {
                        this.$refs.validator.setAllErrors(response.data.errors);
                    }
                    else {
                        openModal(ErrorModal);
                    }
                }
            },
        },
    };
