
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'IneligibleModal',
        extends: BaseModal,

        props: {
            // Note: if you pull these values from copydeck, you need to make
            // sure the namespace is loaded before we need this component, ideally at
            // app startup, since the user may not have a viable Internet connection
            // when this modal appears.
            headline: {
                type: String,
                default: 'Ineligible',
            },
            body: {
                type: String,
                default: 'Please try again later.',
            },
        },

        setup () {
            const { t, vT } = useI18next(['ineligible', 'links', 'modal_content']);
            return { t, vT };
        },

        data () {
            return {
                modalName: 'ineligible',
            };
        },

        computed: {
            rulesLink () {
                return `<a class="nowrap" href="${this.app.tb2URL}${this.t('links:rules_url')}" target="_blank">${this.t('links:rules_copy')}</a>`;
            },
        },
    };
