import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "intro-section gold-divider" }
const _hoisted_2 = { class: "intro-header gold-divider" }
const _hoisted_3 = { class: "page-error gold-divider" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HockeyvilleLogo = _resolveComponent("HockeyvilleLogo")
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_HockeyvilleLogo)
      ])
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("h2", null, _toDisplayString($setup.t('error:headline_copy')), 1),
      _withDirectives(_createElementVNode("p", null, null, 512), [
        [_directive_t, 'error:body_copy']
      ])
    ])
  ], 64))
}