import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "modal media-form",
  role: "dialog",
  "aria-live": "assertive"
}
const _hoisted_2 = { class: "modal-headline" }
const _hoisted_3 = { class: "content-wrapper" }
const _hoisted_4 = { class: "modal-content ice" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "modal-content large-only" }
const _hoisted_7 = { class: "img-preview-wrapper" }
const _hoisted_8 = { class: "img-preview" }
const _hoisted_9 = ["src"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "modal-content small-only ice" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["title", "aria-label"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PhotoForm = _resolveComponent("PhotoForm")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString($setup.t('photo_video.headline_copy')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", null, _toDisplayString($setup.t('photo_video.photo.headline_copy')), 1),
          _createElementVNode("div", {
            innerHTML: $setup.t('photo_video.photo.body_copy')
          }, null, 8, _hoisted_5),
          _createVNode(_component_PhotoForm, {
            params: $data.photoParams,
            onPreview: $options.setPreview,
            onResult: $options.openResults
          }, null, 8, ["params", "onPreview", "onResult"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              ($data.previewImage !== '')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: $data.previewImage,
                    alt: "User Uploaded Photo Preview"
                  }, null, 8, _hoisted_9))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("p", {
            class: "photo-disclaimer",
            innerHTML: $setup.t('photo_video.photo.disclaimer')
          }, null, 8, _hoisted_10)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("p", {
            innerHTML: $setup.t('photo_video.photo.disclaimer')
          }, null, 8, _hoisted_12)
        ])
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        title: $setup.t('modal_content:titles.close'),
        "aria-label": $setup.t('modal_content:titles.close'),
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("i", { class: "far fa-times" }, null, -1)
      ]), 8, _hoisted_13)
    ])
  ]))
}