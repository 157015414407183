
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'EntryConfirmationFormModal',
        extends: BaseModal,
        props: {
            confirmationFor: {
                required: false,
                type: String,
                default: '',
            },
        },

        setup () {
            const { t, vT } = useI18next(['entry_confirmation', 'buttons', 'modal_content']);
            return { t, vT };
        },
        data () {
            return {
                modalName: 'entry-confirmation',
            };
        },
    };
