
    import { useI18next } from '@composables/i18next';
    import openModal, { StoryFormModal } from '../modals';
    import BaseModal from './BaseModal';

    export default {
        name: 'PendingApprovalModal',

        extends: BaseModal,

        props: {
            facilityId: {
                type: String,
                default: '',
            },
        },

        setup () {
            const { t, vT } = useI18next(['pending_approval', 'buttons', 'add_facility', 'links']);
            return { t, vT };
        },

        data () {
            return {

            };
        },
        methods: {
            async openStoryModal () {
                await openModal(StoryFormModal);
            },
        },

    };
