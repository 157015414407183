
    import { useI18next } from '@composables/i18next';
    import { mapState, mapGetters, mapActions } from 'vuex';
    import HockeyvilleLogo from '@components/svgs/HockeyvilleLogo.vue';
    import NHLLogos from '@components/NHLLogos.vue';
    import { openPopup } from '../popup';

    export default {
        name: 'TheFooter',
        components: {
            HockeyvilleLogo,
            NHLLogos,
        },
        setup () {
            const { t, vT } = useI18next(['footer', 'links', 'global', 'buttons']);
            return { t, vT };
        },

        computed: {
            ...mapState([
                'app',
                'profile',
                'ui',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),
            isFR () {
                return this.app.locale === 'fr';
            },
        },

        methods: {
            openPopup,

            ...mapActions({
                logOut: 'profile/logOut',
            }),
        },
    };

