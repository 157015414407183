import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal add-facility-modal",
  role: "dialog",
  "aria-live": "assertive",
  "aria-labelledby": "facility_thanks_title"
}
const _hoisted_2 = { class: "modal-headline" }
const _hoisted_3 = {
  id: "facility_thanks_title",
  class: "add-facility-modal-headline"
}
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["title", "innerHTML"]
const _hoisted_7 = ["title", "aria-label", "innerHTML"]
const _hoisted_8 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString($setup.t('add_facility:thanks.headline')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "add-facility-copy",
          innerHTML: $setup.t('add_facility:thanks.body')
        }, null, 8, _hoisted_5),
        _createElementVNode("button", {
          title: $setup.t('buttons:add_story_now'),
          class: "add_story",
          onClick: _cache[0] || (_cache[0] = $event => ($options.openStoryModal())),
          innerHTML: $setup.t('buttons:add_story_now')
        }, null, 8, _hoisted_6),
        _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("a", {
          href: "#",
          class: "link-button",
          title: $setup.t('links: not_yet_link'),
          "aria-label": $setup.t('links: not_yet_link'),
          onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["prevent"])),
          innerHTML: $setup.t('links:not_yet_link')
        }, null, 8, _hoisted_7)
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        title: $setup.t('modal_content:titles.close'),
        onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("i", { class: "far fa-times" }, null, -1)
      ]), 8, _hoisted_8)
    ])
  ]))
}