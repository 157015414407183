import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "video-player" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("video", {
      ref: "videoPlayer",
      class: "video-js",
      tabindex: "0",
      onPlay: _cache[0] || (_cache[0] = (...args) => ($options.emitPlay && $options.emitPlay(...args)))
    }, null, 544)
  ]))
}