
import axios from 'axios';
import api from './api';
import store from './store/index';

const uploadTypes = new Set(['photo', 'video']);

const uploader = {
    async key (whichType) {
        const params = {
            uid: store.state.profile.id,
            type: whichType,
            language: store.state.app.userLanguage,
        };

        return axios.post(`${api.base}/hello-uploader/key`, params);

    },
    async upload (params, keyData) {
        const formData = new FormData();
        formData.append('upload', params.file, params.name);

        const { uploadUrl } = keyData.data;

        try {
            const axiosInstance = axios.create({});
            // token not needed for uploader call and causes CORS issues, so remove it
            delete axiosInstance.defaults.headers.common['X-HW-Profile-Token'];
            delete axiosInstance.defaults.headers.common['x-aws-waf-token'];

            const options = { headers: { 'Content-Type': undefined } };

            const response = await axiosInstance.post(uploadUrl, formData, options);

            return {
                type: params.type,
                ...response.data,
            };
        }
        catch (error) {
            console.error('could not upload file', error);
        }
    },
    async entries (params) {
        try {
            const response = axios.post(`${api.base}/contest/entries/`, params);
            return response;
        }
        catch (err) {
            return err;
        }
    },
    async submit (params, isYoutube) {
        if (!uploadTypes.has(params.type) || isYoutube) {
            const nonUploaderResponse = this.entries(params);
            return nonUploaderResponse;
        }

        const keyData = await this.key(params.type),
            uploaderResponse = await this.upload(params, keyData);

        const {
            category_id,
            facilityId,
            facilityName,
            facilityProvince,
            name,
            rules,
            type,
        } = params;

        const message = {
            category_id,
            facilityId,
            facilityName,
            facilityProvince,
            name,
            rules,
            type,

            ...uploaderResponse,
        };

        if (type === 'video') {
            message.video = uploaderResponse?.outputs?.orig.url;
        }
        else {
            message.photo = uploaderResponse?.outputs?.gallery.url;
        }

        return this.entries(message);
    },
};

export default uploader;
