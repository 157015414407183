import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal reg-modal",
  role: "dialog",
  "aria-live": "assertive"
}
const _hoisted_2 = { class: "modal-headline" }
const _hoisted_3 = { class: "modal-content reg-modal-content" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["title", "aria-label"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_RegisterForm = _resolveComponent("RegisterForm")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay reg-overlay",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString($setup.t('headline_copy')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", {
          class: "reg-modal-subheadline",
          innerHTML: $setup.t('reg_headline_copy')
        }, null, 8, _hoisted_4),
        _createElementVNode("p", {
          class: "reg-modal-copy",
          innerHTML: $setup.t('reg_body_copy_1')
        }, null, 8, _hoisted_5),
        _createVNode(_component_RegisterForm, {
          onClose: _cache[0] || (_cache[0] = $event => (_ctx.closeModal()))
        })
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        "data-e2e": "register-close-button",
        title: $setup.t('modal_content:titles.close'),
        "aria-label": $setup.t('modal_content:titles.close'),
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("i", { class: "far fa-times" }, null, -1)
      ]), 8, _hoisted_6)
    ])
  ]))
}