
/* This is client-side code. */
/* eslint-env browser */

const
    { isFilled } = require('rocketship-validator'),
    base = require('./validators');

module.exports = {
    register: {
        ...base.register,
        'g-recaptcha-response-v3': { requireCaptcha },
    },
    login: base.login,
    facilitySearch: base.facilitySearch,
    facilityAdd: base.facilityAdd,
    viral: base.viral,
    faqContact: base.faqContact,
    photoEntry: base.photoEntry,
    videoEntry: base.videoEntry,
    storyEntry: base.storyEntry,
    noteEntry: base.noteEntry,
    youtubeEntry: base.youtubeEntry,
    prelaunch: base.prelaunch,
    reminderEmail: base.login,
    uploadKey: {
        ...base.uploadKey,
    },
};

function requireCaptcha (value, field, form) {
    if (!isFilled(value)) {
        // Error out early if we don't have a response.
        this.addError(field, 'NULL');
        return false;
    }
    return true;
}
