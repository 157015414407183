import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "photo-form" }
const _hoisted_2 = {
  key: 0,
  class: "error error-message"
}
const _hoisted_3 = {
  key: 1,
  class: "error error-message"
}
const _hoisted_4 = {
  key: 3,
  class: "preview-container"
}
const _hoisted_5 = { class: "file-name" }
const _hoisted_6 = { class: "submit" }
const _hoisted_7 = { class: "img-preview-wrapper small-only" }
const _hoisted_8 = { class: "img-preview" }
const _hoisted_9 = ["src"]
const _hoisted_10 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseFileInput = _resolveComponent("BaseFileInput")
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      id: "photo_form",
      name: "photo_form",
      autocomplete: "",
      novalidate: "",
      "data-e2e": "photo-form",
      onSubmit: _cache[3] || (_cache[3] = _withModifiers((...args) => ($options.submitPhoto && $options.submitPhoto(...args)), ["prevent"]))
    }, [
      ($data.photoError || $data.defaultError)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($setup.t(`photo_video.photo.errors.${$data.photoError}`) || $setup.t('photo_video.photo.errors.default')), 1))
        : ($data.rulesError)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($setup.t('essay.errors.default')), 1))
          : _createCommentVNode("", true),
      (!$options.isPhotoSelected)
        ? (_openBlock(), _createBlock(_component_BaseFileInput, {
            key: 2,
            modelValue: $data.tempPhoto.photo,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.tempPhoto.photo) = $event)),
            name: "photo",
            label: $setup.t('photo_video.photo.photo_label'),
            error: $setup.t(`photo_video.photo.errors.${$data.photoError}`) || $setup.t('photo_video.photo.errors.default'),
            accept: $props.params.types,
            "button-label": $setup.t('select_file'),
            "max-file-size": $props.params.max,
            "is-error": $data.photoError != '',
            onSelected: $options.selectPhoto
          }, null, 8, ["modelValue", "label", "error", "accept", "button-label", "max-file-size", "is-error", "onSelected"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString($data.tempPhoto.name), 1),
            _createElementVNode("p", null, [
              _createElementVNode("a", {
                class: "bold",
                href: "#",
                onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.anotherPhoto && $options.anotherPhoto(...args)), ["prevent"]))
              }, _toDisplayString($setup.t('photo_video.photo.select_another')), 1)
            ])
          ])),
      _createVNode(_component_BaseCheckbox, {
        modelValue: $data.rules,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.rules) = $event)),
        name: 'originalWork',
        label: $setup.t('photo_video.photo.original_work_checkbox'),
        error: $setup.t('photo_video.photo.original_work_checkbox')
      }, null, 8, ["modelValue", "label", "error"]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            ($data.tempPhoto.photo !== '')
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: $data.tempPhoto.photo
                }, null, 8, _hoisted_9))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("button", {
          class: "btn btn_submit submit_photo_btn",
          type: "submit",
          disabled: !$options.enabledButton,
          "data-e2e": "photo-form-submit"
        }, _toDisplayString($setup.t('buttons:submit')), 9, _hoisted_10)
      ])
    ], 32)
  ]))
}