
    import { useI18next } from '@composables/i18next';
    import openModal, { NominationEssayModal, NominationVideoModal } from '../modals';
    import BaseModal from './BaseModal';


    export default {
        name: 'StoryFormModal',

        extends: BaseModal,

        setup () {
            const { t, vT } = useI18next(['entry_form', 'buttons', 'modal_content']);
            return { t, vT };
        },

        data () {
            return {
                modalName: 'story-form',
            };
        },

        methods: {

            openEssayModal () {
                this.closeModal();
                openModal(NominationEssayModal);
            },

            openVideoModal () {
                this.closeModal();
                openModal(NominationVideoModal);
                this.clearYoutubePlaceholder();
            },

            clearYoutubePlaceholder () {
                const youtubeTextField = document.getElementById('youtube_text_field');

                if (youtubeTextField) {
                    youtubeTextField.placeholder = '';
                }
            },
        },
    };
