import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-id", "onClick"]
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]
const _hoisted_4 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    onScroll: _cache[0] || (_cache[0] = (...args) => ($options.onScroll && $options.onScroll(...args)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.facilities, (facility) => {
      return (_openBlock(), _createElementBlock("div", {
        key: facility.data.id,
        ref_for: true,
        ref: "facilityItem",
        class: _normalizeClass({ 'facility-item': true, selected: facility.data.id == $data.facilityID }),
        "data-id": facility.data.id,
        role: "listitem",
        tabindex: "0",
        onClick: $event => ($options.selectFacilityMarker(facility))
      }, [
        _createElementVNode("p", {
          class: "facility-item-name",
          tabindex: "0",
          title: `${facility.data.name.trim()}`
        }, _toDisplayString($options.facilityName(facility)) + " (" + _toDisplayString(facility.data.city.trim()) + ", " + _toDisplayString(facility.data.state.trim()) + ") ", 9, _hoisted_2),
        _createElementVNode("p", null, [
          ($props.hasMap)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "link-view-on-map",
                title: $options.facilityName(facility)
              }, _toDisplayString($setup.t('facility_list_view_on_map')), 9, _hoisted_3))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("button", {
          class: "button grey button-select-arena",
          onClick: $event => ($options.goToFacility(facility))
        }, _toDisplayString($setup.t('buttons:go_to_arena')), 9, _hoisted_4)
      ], 10, _hoisted_1))
    }), 128))
  ], 32))
}