
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        extends: BaseModal,

        props: {
            type: {
                type: String,
                default: 'story',
            },
        },
        setup () {
            const { t, vT } = useI18next(['buttons', 'entry_form', 'entry_confirmation', 'modal_content']);
            return { t, vT };
        },
    };
