import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "logo-container" }
const _hoisted_2 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "hockeyville-logo",
      src: _ctx.$i18nAsset('img/hockeyville-logo.png'),
      alt: "Kraft Hockeyville"
    }, null, 8, _hoisted_2)
  ]))
}