import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "modal facility-search",
  role: "dialog",
  "aria-live": "assertive"
}
const _hoisted_2 = { class: "modal-headline" }
const _hoisted_3 = { id: "find_facility_title" }
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = { class: "find-facility-google" }
const _hoisted_6 = {
  ref: "mapContainer",
  class: "map_container modal-facility-left"
}
const _hoisted_7 = { class: "modal-main modal-facility-right" }
const _hoisted_8 = { class: "facility-finder-container modal-content-blue top" }
const _hoisted_9 = { class: "base-input field address" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = {
  key: 0,
  class: "error-message aca-error-message",
  role: "alert",
  "aria-live": "assertive"
}
const _hoisted_13 = { class: "facility-search-button-container" }
const _hoisted_14 = ["disabled", "title", "aria-label", "innerHTML"]
const _hoisted_15 = {
  class: "facility-search-details error-no-results",
  tabindex: "0"
}
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = ["title", "aria-label", "innerHTML"]
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = { key: 0 }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = ["title", "aria-label"]
const _hoisted_24 = ["title", "aria-label"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseSelect = _resolveComponent("BaseSelect")
  const _component_Validator = _resolveComponent("Validator")
  const _component_FacilityList = _resolveComponent("FacilityList")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[7] || (_cache[7] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString($setup.t('facility_finder')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, null, 512),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_Validator, {
                ref: "validator",
                guards: _ctx.$options.validatorGuards,
                data: $data.tempSearch
              }, {
                default: _withCtx(({
                                    isError,
                                }) => [
                  _createElementVNode("form", {
                    id: "facility-search-form",
                    autocomplete: "",
                    novalidate: "",
                    "aria-labelledby": "find_facility_title",
                    onSubmit: _cache[3] || (_cache[3] = _withModifiers((...args) => ($options.onSearchFacilities && $options.onSearchFacilities(...args)), ["prevent"]))
                  }, [
                    _createVNode(_component_BaseSelect, {
                      modelValue: $data.tempSearch.state,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.tempSearch.state) = $event)),
                      name: "state",
                      label: $setup.t('select_your'),
                      error: $setup.t('add_facility:error_messages.state'),
                      "select-one": $setup.t('select_one_fields.state'),
                      "is-error": isError('state'),
                      options: $options.stateOptions,
                      class: "province"
                    }, null, 8, ["modelValue", "label", "error", "select-one", "is-error", "options"]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("label", {
                        class: "facility-search-label-copy",
                        for: "query",
                        innerHTML: $setup.t('and_enter')
                      }, null, 8, _hoisted_10),
                      _createElementVNode("div", null, [
                        _withDirectives(_createElementVNode("input", {
                          id: "query",
                          ref: "mapQuery",
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.tempSearch.query) = $event)),
                          class: "input-text",
                          type: "text",
                          name: "query",
                          placeholder: $setup.t('search_placeholder')
                        }, null, 8, _hoisted_11), [
                          [_vModelText, $data.tempSearch.query]
                        ]),
                        (isError('name'))
                          ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString($setup.t('add_facility:error_messages.name')), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("button", {
                          class: "button facility-search-button",
                          name: "submit_facility_search",
                          disabled: $options.isFacilityQueryEmpty || $data.isFacilityRequestPending,
                          title: $setup.t('buttons:search'),
                          "aria-label": $setup.t('buttons:search'),
                          innerHTML: $setup.t('button_search')
                        }, null, 8, _hoisted_14)
                      ])
                    ]),
                    _createElementVNode("fieldset", null, [
                      _createElementVNode("div", null, [
                        _withDirectives(_createElementVNode("input", {
                          id: "place_id",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.placeId) = $event)),
                          type: "hidden",
                          name: "place_id"
                        }, null, 512), [
                          [_vModelText, $data.placeId]
                        ])
                      ])
                    ])
                  ], 32)
                ]),
                _: 1
              }, 8, ["guards", "data"])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("h5", {
                class: "facility-search-results-headline",
                innerHTML: $setup.t('select_facility')
              }, null, 8, _hoisted_16),
              ($data.facilities.length == 0)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "full-address",
                    innerHTML: $setup.t('full_address')
                  }, null, 8, _hoisted_17))
                : _createCommentVNode("", true),
              ($options.showNominateNewFacility)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("p", null, [
                      _createElementVNode("span", {
                        class: "no-results",
                        innerHTML: $setup.t('error_facility_not_found')
                      }, null, 8, _hoisted_18)
                    ]),
                    _createElementVNode("button", {
                      class: "grey",
                      title: $setup.t('add_arena'),
                      "aria-label": $setup.t('add_arena'),
                      onClick: _cache[4] || (_cache[4] = _withModifiers((...args) => ($options.openAddFacilityModal && $options.openAddFacilityModal(...args)), ["prevent"])),
                      innerHTML: $setup.t('add_arena')
                    }, null, 8, _hoisted_19)
                  ], 64))
                : ($options.showNoResults)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", {
                          class: "no-results",
                          innerHTML: $setup.t('error_no_results')
                        }, null, 8, _hoisted_20)
                      ]),
                      (['entry', 'launched'].includes(_ctx.app.phase))
                        ? (_openBlock(), _createElementBlock("p", _hoisted_21, [
                            _createElementVNode("span", {
                              class: "no-results",
                              innerHTML: $setup.t('error_no_results_2')
                            }, null, 8, _hoisted_22),
                            _cache[8] || (_cache[8] = _createTextVNode("  ")),
                            _createElementVNode("a", {
                              href: "#",
                              title: $setup.t('error_no_results_link'),
                              "aria-label": $setup.t('error_no_results_link'),
                              onClick: _cache[5] || (_cache[5] = _withModifiers((...args) => ($options.openAddFacilityModal && $options.openAddFacilityModal(...args)), ["prevent"]))
                            }, _toDisplayString($setup.t('error_no_results_link')), 9, _hoisted_23)
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : (_openBlock(), _createBlock(_component_FacilityList, {
                      key: 3,
                      ref: "facilityListComponent",
                      class: "facility-list",
                      facilities: $data.facilities,
                      onSelectmarker: $options.highlightAndCenterMapMarker,
                      onScrollto: $options.scrollTo,
                      onClose: _ctx.closeModal
                    }, null, 8, ["facilities", "onSelectmarker", "onScrollto", "onClose"]))
            ])
          ])
        ])
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        title: $setup.t('modal_content:titles.close'),
        "aria-label": $setup.t('modal_content:titles.close'),
        onClick: _cache[6] || (_cache[6] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[9] || (_cache[9] = [
        _createElementVNode("i", { class: "far fa-times" }, null, -1)
      ]), 8, _hoisted_24)
    ])
  ]))
}