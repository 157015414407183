import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "modal story-form essay-form",
  role: "dialog",
  "aria-live": "assertive"
}
const _hoisted_2 = { class: "modal-headline" }
const _hoisted_3 = { id: "essay_nom_title" }
const _hoisted_4 = { class: "content-wrapper" }
const _hoisted_5 = { class: "modal-content ice bottom" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "nomination-details large-only" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "modal-content" }
const _hoisted_11 = {
  key: 0,
  class: "error error-message",
  role: "alert"
}
const _hoisted_12 = {
  key: 1,
  class: "error error-message",
  role: "alert"
}
const _hoisted_13 = { class: "canadian-email" }
const _hoisted_14 = { id: "submit" }
const _hoisted_15 = ["disabled", "title", "aria-label"]
const _hoisted_16 = { class: "modal-content ice small-only" }
const _hoisted_17 = { class: "nomination-details small-only" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["title", "aria-label"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseTextarea = _resolveComponent("BaseTextarea")
  const _component_BaseTextareaCounter = _resolveComponent("BaseTextareaCounter")
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")
  const _component_Validator = _resolveComponent("Validator")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[5] || (_cache[5] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString($setup.t('essay.headline_copy')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", {
            class: "nomination-imp-note large-only",
            innerHTML: $setup.t('essay.body_copy_imp_note')
          }, null, 8, _hoisted_6),
          _createElementVNode("p", {
            class: "nomination-imp-note small-only",
            innerHTML: $setup.t('essay.body_copy_imp_note_2')
          }, null, 8, _hoisted_7),
          _createElementVNode("p", _hoisted_8, _toDisplayString($setup.t('essay.body_copy')), 1),
          _createElementVNode("div", {
            class: "large-only",
            onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.openFAQ && $options.openFAQ(...args)), ["prevent"])),
            innerHTML: $setup.t('essay.body_copy_2')
          }, null, 8, _hoisted_9)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_Validator, {
            ref: "validator",
            guards: _ctx.$options.validatorGuards,
            data: $data.tempForm
          }, {
            default: _withCtx(({
                            hasErrors, isError,
                        }) => [
              _createElementVNode("form", {
                id: "entry_form",
                name: "entry_form",
                autocomplete: "",
                novalidate: "",
                "data-e2e": "entry-form",
                "aria-labelledby": "essay_nom_title",
                onSubmit: _cache[2] || (_cache[2] = _withModifiers((...args) => ($options.doSubmit && $options.doSubmit(...args)), ["prevent"]))
              }, [
                (hasErrors && $data.errorRules)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString($setup.t('essay.errors.default')), 1))
                  : _createCommentVNode("", true),
                (hasErrors && $data.errorLength)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString($setup.t('essay.errors.character_length')), 1))
                  : _createCommentVNode("", true),
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (n) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: `tbt${n}`
                  }, [
                    _createVNode(_component_BaseTextarea, {
                      modelValue: $data.tempForm[`question${n}`],
                      "onUpdate:modelValue": $event => (($data.tempForm[`question${n}`]) = $event),
                      label: $setup.t(`essay.question_${n}`),
                      error: $setup.t(`essay.errors.question_${n}`),
                      name: `question_${n}`,
                      placeholder: "",
                      minlength: $data.min,
                      maxlength: $data.max,
                      "is-error": isError(`question${n}`),
                      onInput: (e) => $options.updateCharCount(e, n)
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "error", "name", "minlength", "maxlength", "is-error", "onInput"]),
                    _createVNode(_component_BaseTextareaCounter, {
                      class: "character-count",
                      value: $data.tempForm[`question${n}`],
                      copy: `${$options.charsLeft(n)} ${$setup.t('character_count', { min: $data.min, max: $data.max })}`,
                      maxlength: $data.max
                    }, null, 8, ["value", "copy", "maxlength"])
                  ], 64))
                }), 64)),
                _createVNode(_component_BaseCheckbox, {
                  modelValue: $data.tempForm.rules,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.tempForm.rules) = $event)),
                  name: 'originalWork',
                  label: $setup.t('essay.original_work_checkbox'),
                  "is-error": isError('rules'),
                  error: $setup.t('essay.original_work_checkbox')
                }, null, 8, ["modelValue", "label", "is-error", "error"]),
                _createElementVNode("p", _hoisted_13, [
                  _createElementVNode("i", null, _toDisplayString($setup.t('essay.canadian_email')), 1)
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("button", {
                    id: "essay_submit_btn",
                    class: "essay-submit-btn btn btn_submit",
                    type: "submit",
                    disabled: $data.loading,
                    "data-e2e": "story-form-submit",
                    title: $setup.t('buttons:submit'),
                    "aria-label": $setup.t('buttons:submit')
                  }, _toDisplayString($setup.t('buttons:submit')), 9, _hoisted_15)
                ])
              ], 32)
            ]),
            _: 1
          }, 8, ["guards", "data"])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("p", _hoisted_17, _toDisplayString($setup.t('essay.body_copy')), 1),
          _createElementVNode("p", {
            class: "nomination-imp-note",
            innerHTML: $setup.t('essay.body_copy_imp_note')
          }, null, 8, _hoisted_18),
          _createElementVNode("div", {
            onClick: _cache[3] || (_cache[3] = (...args) => ($options.openFAQ && $options.openFAQ(...args))),
            innerHTML: $setup.t('essay.body_copy_2')
          }, null, 8, _hoisted_19)
        ])
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        title: $setup.t('modal_content:titles.close'),
        "aria-label": $setup.t('modal_content:titles.close'),
        onClick: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("i", { class: "far fa-times" }, null, -1)
      ]), 8, _hoisted_20)
    ])
  ]))
}