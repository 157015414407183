
    import { useI18next } from '@composables/i18next';
    import { mapGetters } from 'vuex';
    import { BaseCheckbox, BaseFileInput } from '@components/form';
    import { track } from '../plugins/WebAnalytics';
    import uploader from '../uploader';

    export default {
        name: 'PhotoForm',

        components: {
            BaseCheckbox,
            BaseFileInput,
        },

        props: {
            params: {
                type: Object,
                required: true,
            },
        },

        setup () {
            const { t, vT } = useI18next(['entry_form', 'buttons']);
            return { t, vT };
        },

        data () {
            return {
                loading: false,
                rules: false,
                photoError: '',
                defaultError: '',
                rulesError: false,
                tempPhoto: {
                    type: 'photo',
                    photo: '',
                    name: '',
                    file: undefined,
                    category_id: 2,
                    rules: false,
                },
            };
        },

        computed: {
            ...mapGetters({
                getFacility: 'app/getFacility',
            }),

            isPhotoSelected () {
                return this.tempPhoto.photo !== '';
            },

            enabledButton () {
                return this.isPhotoSelected && !this.loading && this.rules;
            },
        },

        watch: {
            'tempPhoto.rules': {
                handler (newValue, oldValue) {
                    this.rulesError = !newValue;
                },
            },
        },

        methods: {
            selectPhoto (data) {
                this.photoError = '';
                if (data.error) {
                    this.photoError = data.error;
                    return;
                }

                this.tempPhoto.photo = data.photo;
                this.tempPhoto.name = data.name;
                this.tempPhoto.file = data.file;

                this.$emit('preview', this.tempPhoto.photo);
            },

            anotherPhoto () {
                this.tempPhoto.photo = '';
                this.tempPhoto.name = '';

                this.$emit('preview', this.tempPhoto.photo);
            },

            submitPhoto () {
                const { id, name, state } = this.getFacility;
                this.tempPhoto.facilityId = id;
                this.tempPhoto.facilityName = name;
                this.tempPhoto.facilityProvince = state;

                this.doSubmit(this.tempPhoto);
            },

            async doSubmit (tempForm) {
                try {
                    if (!this.rules) {
                        this.rulesError = true;
                        return;
                    }
                    await uploader.submit(tempForm);

                    this.loading = false;
                    track('submission', { category: `${tempForm.facilityName}, photo`  });
                    this.$emit('result', 'success');
                }
                catch (error) {
                    const typeOfError = error?.response?.data?.result?.error;
                    if (typeOfError === 'limited') {
                        this.$emit('result', 'limited');
                    }
                    else {
                        this.loading = false;
                        this.defaultError = true;
                    }
                    console.log(error);
                }
            },
        },
    };

