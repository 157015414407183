
    import { useI18next } from '@composables/i18next';
    import { mapState } from 'vuex';

    export default {
        name: 'LanguageToggle',

        setup () {
            const { t, vT } = useI18next(['links', 'global']);
            return { t, vT };
        },

        computed: {
            ...mapState(['app']),
        },

        methods: {
            trackUrchinHeader (action, category) {
                this.$webAnalytics.track(action, { category });
            },
        },
    };
