import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "checked", "name"]
const _hoisted_2 = ["for", "innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "error-message aca-error-message",
  role: "alert",
  "aria-live": "assertive"
}


export default /*@__PURE__*/Object.assign({ inheritAttrs: false }, {
  __name: 'BaseCheckbox',
  props: /*@__PURE__*/_mergeModels({
        isError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        error: {
            type: String,
            required: true,
        },
        value: {
            type: Boolean,
            default: false,
        },
    }, {
    "modelValue": { type: Boolean, required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

    
    

    const model = _useModel(__props, "modelValue");

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-checkbox field", { error: __props.isError }])
  }, [
    _withDirectives(_createElementVNode("input", {
      id: __props.name,
      ref: `input_${_ctx._uid}`,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((model).value = $event)),
      type: "checkbox",
      checked: __props.value,
      name: __props.name,
      onChange: _cache[1] || (_cache[1] = $event => (_ctx.$emit('input', $event.target.checked)))
    }, null, 40, _hoisted_1), [
      [_vModelCheckbox, model.value]
    ]),
    _createElementVNode("label", {
      for: __props.name,
      innerHTML: __props.label
    }, null, 8, _hoisted_2),
    (__props.isError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(__props.error), 1))
      : _createCommentVNode("", true)
  ], 2))
}
}

})