
    import { useI18next } from '@composables/i18next';
    import { mapGetters, mapState } from 'vuex';
    import axios from 'axios';
    import { BaseRecaptcha } from '@components/form';
    import VideoPlayer from '@components/VideoPlayer.vue';
    import api from '../api';
    import openModal, { LoginModal } from '../modals';
    import { track } from '../plugins/WebAnalytics';
    import BaseModal from './BaseModal';

    export default {
        name: 'Top4Modal',

        components: {
            BaseRecaptcha,
            VideoPlayer,
        },

        extends: BaseModal,

        props: {
            facility: {
                type: Object,
                required: true,
            },
        },

        setup () {
            const { t } = useI18next(['global', 'top4_modal', 'profile', 'modal_content']);
            return { t };
        },

        data () {
            return {
                isShowingVoteConfirm: false,
                voteNumber: 0,
                recaptchaPubKey: undefined,
                showRecaptchaError: false,
            };
        },

        computed: {
            ...mapGetters({
                isLoggedIn: 'profile/loggedIn',
                inPhase: 'app/inPhase',
                isDev: 'app/isDev',
            }),
            ...mapState(['app', 'profile']),

            getTwitterShareMessage () {
                return this.t(`share:community.${this.app.phase}.twitter.description`);
            },

            entryUrl () {
                return `/community/${this.facility.data.id}/entry/${this.facility.entryId}`;
            },

            shareUrl () {
                return `${this.app.siteURL}?locale=${this.app.userLanguage}#/community/${this.facility.data.id}/entry/${this.facility.entryId}`;
            },

            stateDisplayName () {
                return this.t(`global:state.${this.facility.country}-${this.facility.state}.name`);
            },

            trimmedCityName () {
                return this.app.locale.includes('fr') && this.facility.data.city.includes('Elsipogtog First Nation') ? 'Première nation d’Elsipogtog' : this.facility.data.city.trim();
            },

            trimmedFacilityName () {
                return this.facility.data.name.trim();
            },

            isRecaptchaVisible () {
                const { voteNumber } = this;
                return voteNumber % 15 === 0;
            },

            facilityNameNoSpaces () {
                return this.facility.data.name.replaceAll(/\s/g, '');
            },

            localeVideo () {
                return `https://eprize-content.s3.amazonaws.com/kraft/hockeyville2025/${this.app.locale.split('-')[0]}/top4/${this.facility.videoUrl}.mp4`;
            },

            noVideo () {
                return !this.facility.videoUrl || this.facility.videoUrl === '';
            },
        },

        mounted () {
            if (this.isLoggedIn && ['voting'].includes(this.app.phase)) {
                this.getVoteNumber();
            }
        },
        methods: {
            async goToEntry () {
                await this.closeModal();
                this.$router.push(this.entryUrl);
            },

            async onVote () {
                const payload = {
                    id: this.profile.id,
                    token: this.profile.token,
                    facility_id: this.facility.facilityId,
                    x_channel: this.profile.channel || undefined,
                };

                if (!this.isDev && (this.isRecaptchaVisible && grecaptcha.getResponse() === '')) {
                    this.showRecaptchaError = true;
                    return;
                }

                this.showRecaptchaError = false;

                if (this.isRecaptchaVisible) {
                    payload['g-recaptcha-response'] = grecaptcha.getResponse();
                }

                try {
                    const { data } = await axios.post(`${api.base}/facilities/vote/${this.facility.data.id}`, payload);
                    this.isShowingVoteConfirm = true;
                    this.voteNumber = data.recaptchaLimit;
                    track('voted', { category: `${this.profile.state}, ${this.profile.zip}` });
                    try { window.fbq('track', 'CompleteRegistration'); } // Facebook Pixel
                    catch (e) { return; }
                }
                catch (error) {
                    console.error(error);
                    await this.closeModal();
                    this.$router.push({ path: '/error' });
                }
            },

            toggleBackToVote (e) {
                const target = e.srcElement.nodeName.toLowerCase();
                if (target === 'a' || target === 'strong') {
                    this.isShowingVoteConfirm = !this.isShowingVoteConfirm;
                }
            },

            trackPlay () {
                track('play', { category: `video-top4-${this.facilityNameNoSpaces}` });
            },


            async getVoteNumber () {
                try {
                    const { data } = await axios.get(`${api.base}/facilities/recaptchaNumber`);
                    const { recaptchaNumber } = data.result;

                    this.voteNumber = recaptchaNumber || 0;
                }
                catch (error) {
                    // 404/400 -level response
                    console.error(error);
                    await this.closeModal();
                    this.$router.push({ path: '/error' });
                }
            },

            openLoginModal () {
                openModal(LoginModal);
            },

            trackUrchinModal (action, category) {
                track(action, { category });
            },

            videoOptions (source) {
                return {
                    poster: `https://eprize-content.s3.amazonaws.com/kraft/hockeyville2025/top4/${this.facility.videoUrl}.png`,
                    sources: [
                        {
                            src: source,
                            type: 'video/mp4',
                        },
                    ],
                };
            },
        },
    };
