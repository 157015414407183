
    import { useI18next } from '@composables/i18next';
    import openModal, { StoryFormModal } from '../modals';
    import BaseModal from './BaseModal';

    export default {
        name: 'AddFacilityModalThanks',

        extends: BaseModal,

        setup () {
            const { t, vT } = useI18next(['add_facility', 'global', 'buttons', 'modal_content', 'links']);
            return { t, vT };
        },

        data () {
            return {
                modalName: 'add-facility-thanks',
            };
        },
        methods: {
            openStoryModal () {
                this.closeModal();
                openModal(StoryFormModal);
            },
        },
    };
