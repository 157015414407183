import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal story-form video-form",
  role: "dialog",
  "aria-live": "assertive"
}
const _hoisted_2 = { class: "modal-headline" }
const _hoisted_3 = { class: "content-wrapper" }
const _hoisted_4 = { class: "modal-content ice bottom" }
const _hoisted_5 = { class: "nomination-imp-note large-only" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "nomination-details large-only" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "modal-content" }
const _hoisted_10 = { class: "video-content" }
const _hoisted_11 = { class: "modal-content ice small-only" }
const _hoisted_12 = { class: "nomination-details small-only" }
const _hoisted_13 = { class: "nomination-imp-note" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["title", "aria-label"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_VideoForm = _resolveComponent("VideoForm")
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString($setup.t('essay.headline_copy')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString($setup.t('video.body_copy_imp_note')), 1),
          _createElementVNode("p", {
            class: "nomination-imp-note small-only",
            innerHTML: $setup.t('essay.body_copy_imp_note_2')
          }, null, 8, _hoisted_6),
          _createElementVNode("p", _hoisted_7, _toDisplayString($setup.t('video.body_copy')), 1),
          _createElementVNode("div", {
            class: "large-only",
            onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.openFAQ && $options.openFAQ(...args)), ["prevent"])),
            innerHTML: $setup.t('entry_form:video.body_copy_2', { rulesLink: $options.rulesLink, interpolation: { escapeValue: false } })
          }, null, 8, _hoisted_8)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _withDirectives(_createElementVNode("div", _hoisted_10, null, 512), [
            [_directive_t, 'entry_form:photo_video.video.body_copy']
          ]),
          _createVNode(_component_VideoForm, {
            params: $data.videoParams,
            onResult: $options.openResults
          }, null, 8, ["params", "onResult"])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("p", _hoisted_12, _toDisplayString($setup.t('essay.body_copy')), 1),
          _createElementVNode("p", _hoisted_13, _toDisplayString($setup.t('video.body_copy_imp_note')), 1),
          _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.openFAQ && $options.openFAQ(...args)), ["prevent"])),
            innerHTML: $setup.t('entry_form:video.body_copy_2', { rulesLink: $options.rulesLink, interpolation: { escapeValue: false } })
          }, null, 8, _hoisted_14)
        ])
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        title: $setup.t('modal_content:titles.close'),
        "aria-label": $setup.t('modal_content:titles.close'),
        onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("i", { class: "far fa-times" }, null, -1)
      ]), 8, _hoisted_15)
    ])
  ]))
}